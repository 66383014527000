import UAParser from 'ua-parser-js';

export const UA = new UAParser();
export const setUA = (uaStr) => UA.setUA(uaStr);
// export const browser = UA.getBrowser();
// export const cpu = UA.getCPU();
// export const device = UA.getDevice();
// export const engine = UA.getEngine();
// export const os = UA.getOS();
// export const ua = UA.getUA();

export const mockUserAgent = (userAgent) => {
  // eslint-disable-next-line no-restricted-properties
  window.navigator.__defineGetter__('userAgent', () => userAgent);
};

// TODO: Rework structure, move helper functions to utils
export const setDefaults = (p, d = 'none') => p || d;

export const getNavigatorInstance = () => {
  if (typeof window !== 'undefined' && (window.navigator || navigator)) {
    return window.navigator || navigator;
  }

  return false;
};

export const isIOS13Check = (type) => {
  const nav = getNavigatorInstance();
  return (
    nav &&
    nav.platform &&
    (nav.platform.indexOf(type) !== -1 || (nav.platform === 'MacIntel' && nav.maxTouchPoints > 1 && !window.MSStream))
  );
};
