import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { switchProp } from 'styled-tools';

import { styledContainerQuery } from '../../StyledContainerQuery';

export const CountriesWrap = styled.div<{ buttonDisableAfterClick: boolean }>`
  overflow: hidden;
  transition: 5s all;
  height: auto;

  ${({ buttonDisableAfterClick }) =>
    buttonDisableAfterClick &&
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    css`
      pointer-events: none;
      opacity: 0.6;
    `};
`;

export const Checkboxes = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 0;
  max-height: ${switchProp('expand', {
    true: 'initial',
    false: '56px',
  })};
  transition: 0.5s;
`;

const checkboxMargin = '4px';
export const CheckboxWrap = styled.div`
  display: flex;
  flex-basis: 48%;
  margin: ${checkboxMargin};
`;

export const CheckboxesContainer = styledContainerQuery(Checkboxes)`
  &:container(min-width: 1px and max-width: 235px) {
    justify-content: space-between;

    ${CheckboxWrap} {
      flex-basis: 100%;
    }
  }
  &:container(min-width: 236px) {
    justify-content: space-between;

    ${CheckboxWrap}  {
      flex-basis: 42%;
    }
  }
  &:container(min-width: 360px) {
    justify-content: space-between;

    ${CheckboxWrap}  {
      flex-basis: 31%;
    }
  }
`;

export const CheckboxLabel = styled.label`
  padding-top: 1px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.input`
  margin: 0 3px 0 0;
  cursor: pointer;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-of-type {
    margin-right: 8px;
  },
`;
