import React from 'react';
import { Action, createUseReducer } from '@wpa/redux-utils';

import { State } from '../types';
import { getIPad13, isMobileAndTabletType, setUA } from '../utils/device-detect';
import { useDebounce } from '../hooks/useDebounce';
import { hideKeyboard } from '../utils';
import { rootReducer, setEnabled, setWindowSize, setFullScreen } from './rootSlice';

type Props = {
  initialState: State;
  children: (state: State) => React.ReactElement;
};

const useReducer = createUseReducer<State, Action<any>>('app', rootReducer, { cache: false });

export function Store(props: Props): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
  const { initialState, children } = props;

  const [state] = useReducer(initialState);

  if (process.env.NODE_ENV === 'development') {
    console.log('state', state);
  }

  const onResize = React.useCallback(() => {
    setUA(window.navigator.userAgent);
    const isMobile = isMobileAndTabletType() || !!getIPad13();
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    if (isMobile && !state?.isFullScreen) {
      setFullScreen(true);
    }

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [state?.isFullScreen]);

  const onResizeDebounced = useDebounce(onResize, 10);

  React.useEffect(() => {
    window.addEventListener('resize', onResizeDebounced);
    window.addEventListener('orientationchange', hideKeyboard, false);
    onResize();
    return () => {
      window.removeEventListener('resize', onResizeDebounced);
      window.removeEventListener('orientationchange', hideKeyboard);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.SmartChatBotApp.hideChatWindow = () => setEnabled(false);
  window.SmartChatBotApp.showChatWindow = () => setEnabled(true);

  return children(state);
}
