import { styled } from '@mui/material/styles';

export const WindowRootStyled = styled('div', { name: 'WindowRootStyled', slot: 'Root' })(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: `${theme.custom.windowBorderRadius}`,
  border: `1px solid ${theme.custom.windowBorderColor}`,
  backgroundColor: `${theme.custom.bodyBackground}`,
  // boxShadow: 'rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px',
  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
}));

export const ThoughtsWindowRootStyled = styled(WindowRootStyled)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const ContainerWithThoughtsStyled = styled('div', { name: 'ContainerWithThoughtsStyled', slot: 'Root' })(
  ({ theme }) => ({
    display: 'grid',
    height: '100%',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '& > div': {
      boxShadow: 'none',
    },
  }),
);

export const OfflineMassageStyled = styled('div', { name: 'OfflineMassageStyled', slot: 'Root' })(({ theme }) => ({
  position: 'absolute',
  top: `${theme.custom.headerHeight || '0'}`,
  left: 0,
  right: 0,
  width: '100%',
  boxSizing: 'border-box',
  textAlign: 'center',
  color: `${theme.custom.offlineMassageColor}`,
  backgroundColor: `${theme.custom.offlineMassageBackgroundColor}`,
  opacity: 0.95,
  zIndex: 10,
}));
