import React from 'react';
import Typography from '@mui/material/Typography';

import { Image } from './Image';
import { Video } from './Video/Video';
import { AnimationSpeedOptions, CoefficientType, MessageData } from '../../types';
import { CoefficientSpeed } from '../../enums/coefficient-speed.enum';

export const printMessage2 = (data: MessageData, isFullScreen: boolean): JSX.Element => {
  switch (data.type) {
    case 'text':
      return <Typography variant="body2" dangerouslySetInnerHTML={{ __html: data.componentData }} />;
    case 'image':
      return <Image src={data.componentData} alt="ResponseBotImage" />;
    case 'video':
      return <Video src={data.componentData} isFullScreen={isFullScreen} />;
    default:
      return (
        <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
          {data.inputText || data.componentData}
        </Typography>
      );
  }
};

export const getAnimationSpeedOptions = (coefficient: CoefficientType): AnimationSpeedOptions => {
  switch (String(coefficient)) {
    case CoefficientSpeed.slow:
    case '0': {
      return { animationSpeed: '1s', sendMessageDelay: 2000 };
    }
    case '0.1': {
      return { animationSpeed: '0.95s', sendMessageDelay: 1950 };
    }
    case '0.2': {
      return { animationSpeed: '0.9s', sendMessageDelay: 1900 };
    }
    case '0.3': {
      return { animationSpeed: '0.85s', sendMessageDelay: 1850 };
    }
    case '0.4': {
      return { animationSpeed: '0.8s', sendMessageDelay: 1800 };
    }
    case CoefficientSpeed.average:
    case '0.5': {
      return { animationSpeed: '0.75s', sendMessageDelay: 1750 };
    }
    case '0.6': {
      return { animationSpeed: '0.7s', sendMessageDelay: 1700 };
    }
    case '0.7': {
      return { animationSpeed: '0.65s', sendMessageDelay: 1650 };
    }
    case '0.8': {
      return { animationSpeed: '0.6s', sendMessageDelay: 1600 };
    }
    case '0.9': {
      return { animationSpeed: '0.55s', sendMessageDelay: 1550 };
    }
    case CoefficientSpeed.fast:
    case '1': {
      return { animationSpeed: '0.5s', sendMessageDelay: 1500 };
    }
    default: {
      return { animationSpeed: '0.75s', sendMessageDelay: 1750 };
    }
  }
};

export const getClassForAnimation = (clickedButtonIdx, actionIndex) => {
  const isClicked = clickedButtonIdx !== -1;

  if (isClicked && clickedButtonIdx === actionIndex) {
    return 'scale';
  }

  if (isClicked && clickedButtonIdx !== actionIndex) {
    return 'fade';
  }

  return '';
};
