import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';

export const StyledButton = styled('div', {
  name: 'StyledButton',
  slot: 'Root',
})((props: { theme?: Theme; color: string; background: string }) => ({
  flexGrow: 1,
  marginRight: '5px',
  marginTop: '5px',
  '& button': {
    width: '100%',
    boxShadow: '3px 3px 5px rgba(0,0,0,0.4)',
    background: props.background || props.theme?.custom.fieldBackground,
    borderWidth: `1px`,
    borderStyle: 'solid',
    borderColor: props.theme?.custom.fieldBorderColor,
    textTransform: 'capitalize',
    color: props.color || props.theme?.custom.fieldColor,
    '&:disabled': {
      color: props.color || props.theme?.custom.fieldColor,
      background: props.background || props.theme?.custom.fieldBackground,
      opacity: 0.5,
      cursor: 'not-allowed',
      pointerEvents: 'initial',
    },
    '&:disabled:hover': {
      color: props.color || props.theme?.custom.fieldColor,
      background: props.background || props.theme?.custom.fieldBackground,
    },
  },
}));
