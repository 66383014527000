import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

type ForwardButtonProps = {
  handleScrollToBottom: () => void;
  isButtonVisible: boolean;
};

export const ForwardButtonStyled = styled('div', { name: 'ForwardButton', slot: 'Root' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'center',
  width: '30px',
  height: '30px',
  backgroundColor: '#787878',
  position: 'absolute',
  bottom: '5px',
  right: '20px',
  transition: '0.4s',
  zIndex: 10100,
  cursor: 'pointer',
  opacity: 0.6,
  borderRadius: '50%',
});

export function ForwardButton({ handleScrollToBottom, isButtonVisible }: ForwardButtonProps): JSX.Element | null {
  return isButtonVisible ? (
    <ForwardButtonStyled onClick={handleScrollToBottom}>
      <ExpandMoreIcon />
    </ForwardButtonStyled>
  ) : null;
}
