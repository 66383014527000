import React from 'react';
import { create, Jss } from 'jss';
import {
  ThemeProvider as EmotionThemeProvider,
  CacheProvider as EmotionCacheProvider,
  EmotionCache,
} from '@emotion/react';
import createCache from '@emotion/cache';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import createTheme from '@mui/material/styles/createTheme';
import MuiThemeProvider from '@mui/styles/ThemeProvider';
import useTheme from '@mui/styles/useTheme';
import jssPreset from '@mui/styles/jssPreset';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import MuiStylesProvider from '@mui/styles/StylesProvider';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import type { PaletteMode, Theme, ThemeOptions } from '@mui/material';

import { PaletteType, StaticImages, ElementSizes, Typography } from '../types';

type State = { mode: PaletteMode };
type Action = { type: 'changeTheme'; payload: PaletteMode };
type Reducer = (prevState: State, action: Action) => State;

const ThemeDispatchContext = React.createContext<React.Dispatch<Action> | null>(null);

type Props = {
  paletteMode: PaletteMode;
  palette: PaletteType;
  images: StaticImages;
  elementSizes: ElementSizes;
  typography: Typography;
  children: React.ReactElement;
};

function setEmotionStyles(ref, emotionCache, setEmotionCache) {
  if (ref && !emotionCache) {
    const createdEmotionWithRef = createCache({
      key: 'widget',
      container: ref,
    });
    setEmotionCache(createdEmotionWithRef);
  }
}

function createJssStyles(ref, jss, setJss) {
  if (ref && !jss) {
    const createdJssWithRef = create({ ...jssPreset(), insertionPoint: ref });
    setJss(createdJssWithRef);
  }
}

export function ThemeProvider(props: Props): JSX.Element {
  const { paletteMode, palette, images, elementSizes, typography, children } = props;

  const [jss, setJss] = React.useState<Jss>();
  const [emotionCache, setEmotionCache] = React.useState<EmotionCache>();
  const [paletteType, dispatch] = React.useReducer<Reducer, State>(
    (state: State, { type, payload }: Action): State => (type === 'changeTheme' ? { mode: payload } : state),
    { mode: paletteMode },
    (state) => state,
  );

  const memoizedTheme = React.useMemo(() => {
    const themeOptions: ThemeOptions = {
      custom: { ...palette, ...elementSizes },
      customImages: images,
      typography,
      palette: { mode: paletteType.mode },
      overrides: {
        MuiButton: {
          root: {
            borderRadius: 7,
            color: palette.fieldColor,
            background: palette.fieldBackground,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: palette.fieldBorderColor === 'none' ? 'transparent' : palette.fieldBorderColor,
            textTransform: 'none',
            boxShadow: '3px 3px 5px rgba(0,0,0,0.4)',
          },
          sizeSmall: { padding: '4px 15px' },
        },
      },
    };
    const mergedTheme = createTheme(themeOptions);

    return responsiveFontSizes(mergedTheme);
  }, [palette, elementSizes, images, typography, paletteType.mode]);

  return (
    <>
      <div
        ref={(ref) => {
          createJssStyles(ref, jss, setJss);
          setEmotionStyles(ref, emotionCache, setEmotionCache);
        }}
      />
      {jss && emotionCache && (
        <MuiThemeProvider theme={memoizedTheme}>
          <EmotionThemeProvider theme={memoizedTheme}>
            <MuiStylesProvider jss={jss} generateClassName={createGenerateClassName({ seed: 'SB' })}>
              <EmotionCacheProvider value={emotionCache}>
                <ThemeDispatchContext.Provider value={dispatch}>
                  <ScopedCssBaseline>{children}</ScopedCssBaseline>
                </ThemeDispatchContext.Provider>
              </EmotionCacheProvider>
            </MuiStylesProvider>
          </EmotionThemeProvider>
        </MuiThemeProvider>
      )}
    </>
  );
}

export const useChangeTheme = (): (() => void) => {
  const dispatch = React.useContext(ThemeDispatchContext);
  const theme = useTheme<Theme>();

  return React.useCallback(() => {
    if (dispatch) {
      dispatch({
        type: 'changeTheme',
        payload: theme.palette.mode === 'light' ? 'dark' : 'light',
      });
    }
  }, [theme.palette.mode, dispatch]);
};
