import React from 'react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { format } from 'date-fns';

export function StartsSequence({ time, classes, isMine, botName, startsSequence, withBotIcon, withUserIcon }) {
  const formattedTime = format(new Date(time), 'hh:mm');

  return (
    startsSequence && (
      <div
        className={clsx(classes.info, {
          [classes.mineInfo]: isMine,
          [classes.infoWithIcon]: !isMine && withBotIcon,
          [classes.mineInfoWithIcon]: isMine && withUserIcon,
        })}
      >
        <Typography variant="caption">
          {/* eslint-disable-next-line sonarjs/no-nested-template-literals */}
          {`${isMine ? '' : `${botName}, `}${formattedTime}`}
        </Typography>
      </div>
    )
  );
}
