import { setDefaults, isIOS13Check, getNavigatorInstance, UA } from './get-ua-data';
import { BrowserTypes, DeviceTypes, OsTypes } from './types';

const isMobileType = () => {
  const device = UA.getDevice();

  return device.type === DeviceTypes.Mobile;
};
const isTabletType = () => {
  const device = UA.getDevice();

  return device.type === DeviceTypes.Tablet;
};

export const isMobileAndTabletType = (): boolean => {
  const device = UA.getDevice();

  switch (device.type) {
    case DeviceTypes.Mobile:
    case DeviceTypes.Tablet:
      return true;
    default:
      return false;
  }
};

const isEdgeChromiumType = () => {
  const ua = UA.getUA();

  return typeof ua === 'string' && ua.indexOf('Edg/') !== -1;
};

const isSmartTVType = () => {
  const device = UA.getDevice();

  return device.type === DeviceTypes.SmartTv;
};
const isBrowserType = () => {
  const device = UA.getDevice();

  return device.type === DeviceTypes.Browser;
};
const isWearableType = () => {
  const device = UA.getDevice();

  return device.type === DeviceTypes.Wearable;
};
const isConsoleType = () => {
  const device = UA.getDevice();

  return device.type === DeviceTypes.Console;
};
const isAndroidType = () => {
  const os = UA.getOS();

  return os.name === OsTypes.Android;
};
const isWindowsType = () => {
  const os = UA.getOS();

  return os.name === OsTypes.Windows;
};
const isMacOsType = () => {
  const os = UA.getOS();

  return os.name === OsTypes.MAC_OS;
};
const isWinPhoneType = () => {
  const os = UA.getOS();

  return os.name === OsTypes.WindowsPhone;
};
const isIOSType = () => {
  const os = UA.getOS();

  return os.name === OsTypes.IOS;
};
const isChromeType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.Chrome;
};
const isFirefoxType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.Firefox;
};
const isChromiumType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.Chromium;
};
const isEdgeType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.Edge;
};
const isYandexType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.Yandex;
};
const isSafariType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.Safari || browser.name === BrowserTypes.MobileSafari;
};
const isMobileSafariType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.MobileSafari;
};
const isOperaType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.Opera;
};
const isIEType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.InternetExplorer || browser.name === BrowserTypes.Ie;
};
const isMIUIType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.MIUI;
};
const isSamsungBrowserType = () => {
  const browser = UA.getBrowser();

  return browser.name === BrowserTypes.SamsungBrowser;
};

const isElectronType = () => {
  const nav = getNavigatorInstance();
  const ua = nav && nav.userAgent && nav.userAgent.toLowerCase();

  return typeof ua === 'string' ? /electron/.test(ua) : false;
};

const getIOS13 = () => {
  const nav = getNavigatorInstance();
  return (
    nav &&
    (/iPad|iPhone|iPod/.test(nav.platform) || (nav.platform === 'MacIntel' && nav.maxTouchPoints > 1)) &&
    // @ts-ignore
    !window.MSStream
  );
};

export const getIPad13 = () => isIOS13Check('iPad');
const getIphone13 = () => isIOS13Check('iPhone');
const getIPod13 = () => isIOS13Check('iPod');

const getBrowserFullVersion = () => {
  const browser = UA.getBrowser();

  return setDefaults(browser.version);
};
const getBrowserVersion = () => {
  const browser = UA.getBrowser();

  return setDefaults(browser.major);
};
const getOsVersion = () => {
  const os = UA.getOS();

  return setDefaults(os.version);
};
const getOsName = () => {
  const os = UA.getOS();

  return setDefaults(os.name);
};
const getBrowserName = () => {
  const browser = UA.getBrowser();

  return setDefaults(browser.name);
};
const getMobileVendor = () => {
  const device = UA.getDevice();

  return setDefaults(device.vendor);
};
const getMobileModel = () => {
  const device = UA.getDevice();

  return setDefaults(device.model);
};
const getEngineName = () => {
  const engine = UA.getEngine();

  return setDefaults(engine.name);
};
const getEngineVersion = () => {
  const engine = UA.getEngine();

  return setDefaults(engine.version);
};
const getUseragent = () => {
  const ua = UA.getUA();

  return setDefaults(ua);
};
const getDeviceType = () => {
  const device = UA.getDevice();

  return setDefaults(device.type, 'browser');
};

export const isSmartTV = isSmartTVType();
export const isConsole = isConsoleType();
export const isWearable = isWearableType();
export const isMobileSafari = isMobileSafariType() || getIPad13();
export const isChromium = isChromiumType();
export const isMobile = isMobileAndTabletType() || getIPad13();
export const isMobileOnly = isMobileType();
export const isTablet = isTabletType() || getIPad13();
export const isBrowser = isBrowserType();
export const isDesktop = isBrowserType();
export const isAndroid = isAndroidType();
export const isWinPhone = isWinPhoneType();
export const isIOS = isIOSType() || getIPad13();
export const isChrome = isChromeType();
export const isFirefox = isFirefoxType();
export const isSafari = isSafariType();
export const isOpera = isOperaType();
export const isIE = isIEType();
export const osVersion = getOsVersion();
export const osName = getOsName();
export const fullBrowserVersion = getBrowserFullVersion();
export const browserVersion = getBrowserVersion();
export const browserName = getBrowserName();
export const mobileVendor = getMobileVendor();
export const mobileModel = getMobileModel();
export const engineName = getEngineName();
export const engineVersion = getEngineVersion();
export const getUA = getUseragent();
export const isEdge = isEdgeType() || isEdgeChromiumType();
export const isYandex = isYandexType();
export const deviceType = getDeviceType();
export const isIOS13 = getIOS13();
export const isIPad13 = getIPad13();
export const isIPhone13 = getIphone13();
export const isIPod13 = getIPod13();
export const isElectron = isElectronType();
export const isEdgeChromium = isEdgeChromiumType();
export const isLegacyEdge = isEdgeType() && !isEdgeChromiumType();
export const isWindows = isWindowsType();
export const isMacOs = isMacOsType();
export const isMIUI = isMIUIType();
export const isSamsungBrowser = isSamsungBrowserType();
