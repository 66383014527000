import type { Path } from 'ramda';
import { path as pathRamda } from 'ramda';

export type Selector<T> = (obj: any) => T;

export const createSelector = (path: Path): ((obj: any) => any) => pathRamda(path);
export const createSelectorWithFallbackPath =
  <T>(fallbackPath: Path, path: Path): Selector<T> =>
  (obj: any) => {
    const firstTry = pathRamda<T>(path, obj);
    let secondTry;

    if (firstTry) {
      secondTry = pathRamda<T>(fallbackPath, obj);
    }
    return firstTry || secondTry;
  };
