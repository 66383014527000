import React from 'react';
import useTheme from '@mui/styles/useTheme';

import { Theme } from '@mui/material';
import { Wrapper, Img, ImgWrapper, /* Text, */ TypingWrapper, TypingIndicator, Dot } from './styled';

/* type TypingProps = {
  content: string;
}; */

export function Typing(/* { content }: TypingProps */): JSX.Element | null {
  const theme = useTheme<Theme>();
  const { typingGif } = theme.customImages;
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timer);
  }, []);

  return show ? (
    <Wrapper>
      {typingGif ? (
        <ImgWrapper>
          <Img src={typingGif} alt="" />
        </ImgWrapper>
      ) : (
        <TypingWrapper>
          <TypingIndicator>
            <Dot />
            <Dot />
            <Dot />
          </TypingIndicator>
          {/* {content && <Text>{content}</Text>} */}
        </TypingWrapper>
      )}
    </Wrapper>
  ) : null;
}
