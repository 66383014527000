import React from 'react';

import { isMobile } from './selectors';

enum OrientationType {
  portrait = 'portrait',
  landscape = 'landscape',
}

type MobileOrientation = {
  isPortrait: boolean;
  isLandscape: boolean;
  orientation: OrientationType;
};

export function useMobileOrientation(): MobileOrientation {
  const [state, setState] = React.useState<MobileOrientation>(() => {
    const orientation = window.innerWidth > window.innerHeight ? 90 : 0;
    return {
      isPortrait: orientation === 0,
      isLandscape: orientation === 90,
      orientation: orientation === 0 ? OrientationType.portrait : OrientationType.landscape,
    };
  });

  const handleOrientationChange = React.useCallback(() => {
    const orientation = window.innerWidth > window.innerHeight ? 90 : 0;
    const next: MobileOrientation = {
      isPortrait: orientation === 0,
      isLandscape: orientation === 90,
      orientation: orientation === 0 ? OrientationType.portrait : OrientationType.landscape,
    };

    if (state.orientation !== next.orientation) {
      setState(next);
    }
  }, [state.orientation]);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && isMobile) {
      handleOrientationChange();
      window.addEventListener('load', handleOrientationChange, false);
      window.addEventListener('resize', handleOrientationChange, false);
    }

    return () => {
      window.removeEventListener('resize', handleOrientationChange, false);
      window.removeEventListener('load', handleOrientationChange, false);
    };
  }, [handleOrientationChange]);

  return state;
}
