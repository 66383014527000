import React, { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { Scrollbar } from 'react-scrollbars-custom';
// import List from 'react-virtualized/dist/es/List';

import { ForwardButton } from '../ForwardButton/ForwardButton';
import { /* scrollToBottom  , */ scrollDown } from './BodyHelper';
import { BodyWrapper } from './styled';
// import { BodyContentVirtualized } from './BodyContentVirtualized';
import { BodyContent } from './BodyContent';

function BaseBody(props) {
  const { sendMessage, isOnline /* , width, height */ } = props;

  const bodyRef = React.useRef(null);
  // const scrollRef = React.useRef<List | null>(null);
  const scrollRef = React.useRef<Scrollbar | null>(null);
  const {
    ref: iORef,
    inView,
    entry,
  } = useInView({
    delay: 100,
    threshold: 0,
  });

  // const handleScrollToBottom = useCallback(() => scrollToBottom(scrollRef), []);
  const handleScrollToBottom = useCallback(() => scrollDown(scrollRef), []);

  React.useEffect(() => {
    const checkScrollHandle = () => {
      setTimeout(() => {
        handleScrollToBottom();
      }, 500);
    };
    window.addEventListener('orientationchange', checkScrollHandle);

    return () => {
      window.removeEventListener('orientationchange', checkScrollHandle);
    };
  }, [handleScrollToBottom]);

  const isButtonVisible = !!(scrollRef.current && !inView && entry);

  return (
    <BodyWrapper ref={bodyRef} /* style={{ width, height }} */>
      <BodyContent iORef={iORef} isOnline={isOnline} scrollRef={scrollRef} sendMessage={sendMessage} />
      <ForwardButton handleScrollToBottom={handleScrollToBottom} isButtonVisible={isButtonVisible} />
    </BodyWrapper>
  );
}

export const Body = React.memo(BaseBody);
