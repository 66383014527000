import styled from '@emotion/styled';

export const Img = styled.img`
  z-index: 10800;
  margin: auto;
  max-width: 100%;
`;

export const ZoomButton = styled.button`
  width: 46px;
  height: 46px;
  background: #222;
  border-radius: 50%;
  margin: 0 20px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #fff;
    svg {
      fill: #222;
    }
  }
  transition: 0.3s ease;

  & svg {
    height: 36px;
    width: 36px;
    fill: #fff;
  }
`;

export const ZoomButtonsContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
`;
export const ClosedButton = styled.button`
  border: 0;
  width: 3.5em;
  height: 3.5em;
  text-align: left;
  background: #222;
  border-radius: 50%;
  position: fixed;
  z-index: 2147483647;
  top: 2.5em;
  right: 2.5em;
  transition: color 0.3s ease;
  outline: 0;
  cursor: pointer;

  &:before,
  :after {
    width: 2px;
    background-color: #fff;
    content: '';
    position: absolute;
    top: 20%;
    bottom: 20%;
    left: 50%;
    margin-left: -1px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover,
  :focus {
    background-color: #fff;
  }

  &:hover:before,
  :hover:after,
  :focus:before,
  :focus:after {
    background-color: #222;
  }

  @media all and (max-width: 500px) and (orientation: portrait),
    all and (max-height: 430px) and (orientation: landscape) {
    width: 1.75em;
    height: 1.75em;
    top: 15px;
    right: 10px;
  }
`;

// export const ModalWrapper = styled.div`
//   & div {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin: auto;
//   }
// `;
