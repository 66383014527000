import React from 'react';

import { Payload, DefaultPayload, IPostMessage } from '../types';

export type EventHandler<IncPayload extends Payload = DefaultPayload, OutPayload extends Payload = DefaultPayload> = (
  callback: (data: IPostMessage<OutPayload>) => unknown,
  payload: IncPayload,
) => unknown;

const postMessage = <P extends Payload = DefaultPayload>(
  data: IPostMessage<P>,
  target: MessageEvent['source'],
  origin = '*',
) => target?.postMessage(data, { targetOrigin: origin });

export function usePostMessage<
  IncPayload extends Payload = DefaultPayload,
  OutPayload extends Payload = DefaultPayload,
>(eventType: string, eventHandler?: EventHandler<IncPayload, OutPayload>) {
  const [history, setHistory] = React.useState<Array<IPostMessage<IncPayload>>>([]);
  const originRef = React.useRef<string>();
  const sourceRef = React.useRef<MessageEvent['source']>(null);

  const sendToSender = React.useCallback(
    (data: IPostMessage<OutPayload>) => postMessage(data, sourceRef.current, originRef.current),
    [],
  );

  const sendToParent = (data: IPostMessage<OutPayload>) => {
    const { opener, parent } = window;

    postMessage(data, opener || parent);
  };

  const onWatchEventHandler = React.useCallback(
    (event: MessageEvent) => {
      const { type, payload } = event.data;

      if (type === eventType) {
        sourceRef.current = event.source;
        originRef.current = event.origin;
        setHistory((old) => [...old, payload]);

        if (typeof eventHandler === 'function') {
          eventHandler(sendToSender, payload);
        }
      }
    },
    [eventType, eventHandler, sendToSender],
  );

  React.useEffect(() => {
    window.addEventListener('message', onWatchEventHandler);

    return () => {
      window.removeEventListener('message', onWatchEventHandler);
    };
  }, [eventType, onWatchEventHandler]);

  return { history, sendToParent };
}
