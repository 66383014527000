/* eslint-disable react/require-default-props,react/destructuring-assignment */
import React from 'react';
import Button from '@mui/material/Button';

import { StyledButton } from './styled';

type ButtonProps = {
  onClick: (e) => void;
  disabled?: boolean;
  fieldColor?: string;
  fieldBackground?: string;
  fullWidth?: boolean;
  className?: string;
  actionIndex?: number;
  children: React.ReactChild;
};

export const BaseButton = React.forwardRef<HTMLDivElement, ButtonProps>((props, ref) => {
  const {
    fullWidth = false,
    disabled = false,
    fieldColor = '',
    fieldBackground = '',
    children,
    onClick,
    ...rest
  } = props;

  return (
    // @ts-ignore
    <StyledButton ref={ref} color={fieldColor} background={fieldBackground}>
      <Button size="small" onClick={onClick} disabled={disabled} fullWidth={fullWidth} {...rest}>
        {children}
      </Button>
    </StyledButton>
  );
});
