import React from 'react';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { v4 as uuidv4 } from 'uuid';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import useTheme from '@mui/styles/useTheme';
import { Theme } from '@mui/material';
import isEmpty from 'lodash/fp/isEmpty';

import { BotMessage, State } from '../../types';
import { setIsTyping, setMessagesHistory } from '../../store/rootSlice';
import { useOnlineStatus } from '../../hooks/useOnlineStatus';
import { messageMapper } from '../../utils/messageMapper';
import { Header, Footer, ResizableMemo } from '../index';
import { Body } from '../Body';
import {
  ContainerWithThoughtsStyled,
  OfflineMassageStyled,
  ThoughtsWindowRootStyled,
  WindowRootStyled,
} from './styled';
import { formatResponseText, sendMessageFactory, sendMessageToWS, start, suggestParse } from './helpers';
import { PortalContext } from './PortalContext';
import { SENDER_TYPE } from '../../constants';
import { AccuracyQnAMarker } from '../../enums/marker.enum';
import { ThoughtsBody } from '../Body/BodyContent';

type Props = {
  userID: string;
  withThoughts: boolean;
  headerText?: string;
  clientData: string;
  channelKey: string;
  hubConnection?: HubConnection;
  isCopyrightVisible?: boolean;
  isEnabled: boolean | undefined;
  portalRef: React.MutableRefObject<HTMLDivElement | null>;
  messagesHistory: State['messagesHistory'];
  appendTo?: State['appendTo'];
  autoFocus?: State['autoFocus'];
};

const ignoringMessages = new Set(['Whoops 🤦 Something is wrong on our side']);

function ContainerComponent({ appendTo, isEnabled, children }: any) {
  return appendTo ? (
    <div style={{ position: 'absolute', inset: 0 }}>{children}</div>
  ) : (
    <Slide
      direction="up"
      in={isEnabled}
      style={{
        transformOrigin: '0 0 0',
      }}
      {...(isEnabled ? { timeout: 900 } : {})}
    >
      <Paper elevation={0} component={ResizableMemo}>
        {children}
      </Paper>
    </Slide>
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ChatWindow = React.memo<Props>((props) => {
  const {
    userID,
    headerText,
    clientData,
    channelKey,
    hubConnection,
    isEnabled,
    portalRef,
    messagesHistory,
    isCopyrightVisible,
    withThoughts,
    appendTo,
    autoFocus,
  } = props;

  const { custom: customTheme } = useTheme<Theme>();
  const isOnline = useOnlineStatus();
  const [reconnectMessage, setReconnectMessage] = React.useState('');
  // const [footerHeight, setFooterHeight] = React.useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendMessage = React.useCallback(sendMessageFactory(userID, clientData, channelKey, hubConnection), [
    userID,
    clientData,
    channelKey,
    hubConnection,
  ]);

  const afterConnectionCallback = React.useCallback(() => {
    if (isEmpty(messagesHistory)) {
      sendMessage({ value: 'hello', type: 'text', isFirst: true });
    }
  }, [messagesHistory, sendMessage]);

  React.useEffect(() => {
    if (
      hubConnection &&
      hubConnection.state !== HubConnectionState.Connecting &&
      hubConnection.state !== HubConnectionState.Connected &&
      hubConnection.state !== HubConnectionState.Reconnecting
    ) {
      (async () => {
        await start(hubConnection, afterConnectionCallback);
      })();
    }
  }, [afterConnectionCallback, hubConnection]);

  React.useEffect(() => {
    if (hubConnection) {
      hubConnection.on('Send', async (response) => {
        const responseText = formatResponseText(response);
        let responseActions: string[] = [];

        if (response.type === 'text' && /<\/suggest>/.test(response.responseActions)) {
          responseActions = suggestParse(response.responseActions);
        }

        const { componentData, image, imageSize, mapped, topImage } = await messageMapper({
          type: response.type,
          responseText,
          responseImageURL: response.responseImageURL || '',
          responseVideoURL: response.responseVideoURL || '',
        });

        const message: BotMessage = {
          type: SENDER_TYPE.bot,
          isMine: false,
          endsSequence: true,
          startsSequence: true,
          marker: AccuracyQnAMarker.None,
          hideMarkingButtons: ignoringMessages.has(responseText),
          data: {
            isThought: response.isThought,
            type: response.type,
            responseText,
            responseActions,
            responseImageURL: response.responseImageURL || '',
            id: uuidv4(),
            nextResponse: response.nextResponse,
            time: new Date(),
            unread: true,
            image,
            imageSize,
            componentData,
            mapped,
            topImage,
            lastMessage: false,
            disabled: false,
            incomingEventId: response.incomingEventId,
          },
        };

        if (!message.data.nextResponse) {
          setIsTyping(message.data.isThought);
          message.data.lastMessage = true;
        }

        setMessagesHistory(message);
      });
    }

    return () => {
      if (hubConnection) {
        hubConnection.off('Send');
      }
    };
  }, [hubConnection]);

  React.useEffect(() => {
    if (hubConnection) {
      hubConnection.onreconnecting(() => setReconnectMessage(`Reconnecting...`));

      hubConnection.onreconnected(() => {
        setReconnectMessage('');
        if (
          messagesHistory &&
          messagesHistory.length &&
          messagesHistory[messagesHistory.length - 1].type === SENDER_TYPE.user
        ) {
          const lastUserMessage = messagesHistory.pop();

          if (lastUserMessage) {
            sendMessageToWS(hubConnection, lastUserMessage);
          }
        }
      });

      hubConnection.onclose(() => console.info('SmartBot => ', 'Connection closed'));
    }
  }, [messagesHistory, hubConnection]);

  // const headerHeight = parseInt(String(customTheme.headerHeight).trim().split('px')[0], 10);

  return (
    <PortalContext.Provider value={portalRef}>
      <div>
        <ContainerComponent isEnabled={isEnabled} appendTo={appendTo}>
          {withThoughts ? (
            <ContainerWithThoughtsStyled>
              <ThoughtsWindowRootStyled>
                <ThoughtsBody />
              </ThoughtsWindowRootStyled>
              <WindowRootStyled>
                {customTheme.headerHeight && <Header>{headerText}</Header>}
                <OfflineMassageStyled>
                  {!isOnline && <div style={{ padding: 5 }}>No internet connection</div>}
                </OfflineMassageStyled>
                <Body sendMessage={sendMessage} isOnline={isOnline} />
                <Footer autoFocus={autoFocus} sendMessage={sendMessage} isCopyrightVisible={isCopyrightVisible} />
              </WindowRootStyled>
            </ContainerWithThoughtsStyled>
          ) : (
            <WindowRootStyled>
              {customTheme.headerHeight && <Header>{headerText}</Header>}
              <OfflineMassageStyled>
                {!isOnline && <div style={{ padding: 5 }}>No internet connection</div>}
                {reconnectMessage && <div style={{ padding: 5 }}>{reconnectMessage}</div>}
              </OfflineMassageStyled>
              <Body sendMessage={sendMessage} isOnline={isOnline} />
              <Footer autoFocus={autoFocus} sendMessage={sendMessage} isCopyrightVisible={isCopyrightVisible} />
            </WindowRootStyled>
          )}
        </ContainerComponent>
      </div>
    </PortalContext.Provider>
  );
});
