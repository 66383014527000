import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

const typingAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0px);
  }
`;

export const Wrapper = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: '15px',
});

export const ImgWrapper = styled('div')(({ theme }) => ({
  background: `${theme.customImages.typingGif ? 'none' : '#e5e5e5'}`,
  width: '55px',
  height: '30px',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-around',
  margin: '10px 0 0 10px',
}));

export const Img = styled('img')({
  borderRadius: '10px',
  maxWidth: '35px',
  maxHeight: '20px',
  margin: 'auto auto',
});

export const TypingWrapper = styled('div')({
  boxSizing: 'content-box',
  fontSize: 'inherit',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
});

export const TypingIndicator = styled('div')({
  boxSizing: 'inherit',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Dot = styled('div')(({ theme }) => ({
  boxSizing: 'inherit',
  animation: `1.5s ${typingAnimation} infinite ease-in-out`,
  borderRadius: '100%',
  display: 'inline-block',
  height: `${theme.custom.typingDotSize}`,
  width: `${theme.custom.typingDotSize}`,
  backgroundColor: `${theme.custom.typingDotBackgroundColor}`,
  marginRight: '5px',

  '&:last-child': {
    marginRight: 0,
  },
  '&:nth-of-type(1)': {
    boxSizing: 'inherit',
  },
  '&:nth-of-type(2)': {
    animationDelay: '300ms',
  },
  '&:nth-of-type(3)': {
    animationDelay: '400ms',
  },
}));

export const Text = styled('div')(({ theme }) => ({
  boxSizing: 'inherit',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: `${theme.custom.typingTextColor}`,
  margin: '0 0 0 0.5em',
  userSelect: 'none',
}));
