import React from 'react';

export const noop = () => {
  /**/
};

type ValueType = any;
type ReturnType = [ValueType, (ValueType) => void];

export const useControllableState = (value: ValueType, initialValue: ValueType): ReturnType => {
  const initial = typeof value !== 'undefined' ? value : initialValue;
  const [stateValue, setStateValue] = React.useState(initial);
  const effectiveValue = typeof value !== 'undefined' ? value : stateValue;

  return [
    effectiveValue,
    (newValue) => {
      setStateValue(newValue);
    },
  ];
};
