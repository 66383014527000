import { CoefficientSpeed } from './enums/coefficient-speed.enum';
import { ChatConfig } from './types';

export const defaultIcons = {
  iconUrl: 'https://s3.eu-west-1.amazonaws.com/files.smartbot/devshell/smart-bot/images/Avatar.svg',
  logoUrl: 'https://s3.eu-west-1.amazonaws.com/files.smartbot/devshell/smart-bot/images/Logo.svg',
  iconUser: 'https://s3.eu-west-1.amazonaws.com/files.smartbot/devshell/smart-bot/images/IconUser.svg',
};

export const defaultChatConfig: ChatConfig = {
  extendedMode: false,
  cacheStorage: 'sessionStorage',
  isFullScreen: undefined,
  withThoughts: false,
  isEnabled: undefined,
  hideIcon: false,
  disableDraggable: false,
  withBotIcon: false,
  withUserIcon: false,
  showSequence: true,
  autoFocus: true,
  bodyScrollLock: true,
  animationEnabled: true,
  animationSpeed: CoefficientSpeed.average,
  footerButtons: [
    /* { label: '🏡 Menu', command: 'Menu' }, { label: '⭐ Feedback', command: 'Feedback' }, */
  ],
  chatIconAlignment: {
    verticalAlignmentType: 'bottom',
    verticalAlignment: 95,
    horizontalAlignmentType: 'right',
    horizontalAlignment: 20,
  },
  chatWindowAlignment: {
    verticalAlignmentType: 'bottom',
    verticalAlignment: 20,
    horizontalAlignmentType: 'right',
    horizontalAlignment: 30,
  },
  fullScreenGapsMobile: { top: 0, right: 0, bottom: 0, left: 0 },
  fullScreenGapsDesktop: { top: 0, right: 0, bottom: 0, left: 0 },
  staticImages: {
    iconUrl: defaultIcons.iconUrl,
    logoUrl: defaultIcons.logoUrl,
    typingGif: '',
    sendImgUrl: '',
    messageIconUrl: defaultIcons.iconUser,
    messageIconUrlBot: defaultIcons.iconUrl,
  },
  elementSizes: {
    logoSize: '100%',
    headerHeight: '50px',
    windowBorderRadius: '7px',
    scrollWidth: '9px',
    typingDotSize: '7px',
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
  },
  paletteType: 'light',
  paletteTypes: {
    light: {
      /* --------Bot icon button-------- */
      iconMessageBackground: '#f15a22',
      iconMessageColor: '#ffffff',
      iconBackground: '#f15a22',
      /* --------ChatWindow-------- */
      bodyBackground: '#F5F8FF',
      windowBorderColor: 'none',
      offlineMassageColor: 'aliceblue',
      offlineMassageBackgroundColor: '#b52424',
      /* --------Scroll-------- */
      scrollBackground: '#efefef',
      scrollThumbBackground: '#b5b7bd',
      /* --------Header-------- */
      headerColor: '#ffffff',
      headerBackground: '#281e15',
      headerBorderColor: 'transparent',
      /* --------Date-------- */
      dateBackground: '#adaeb2',
      dateText: '#ffffff',
      /* --------Body-------- */
      typingDotBackgroundColor: '#f15a22',
      typingTextColor: '#f15a22',
      /* --------User message-------- */
      userTimeColor: '#007aff',
      messageColor: '#ffffff',
      messageBackground: '#007aff',
      messageBorderColor: 'transparent',
      messageIconBackground: '#007aff',
      /* --------Bot message-------- */
      linkColor: '#021D61',
      clickedOptionColor: '',
      messageColorBot: '#ffffff',
      botNameTimeColor: '#f15a22',
      messageBackgroundBot: '#f15a22',
      messageBorderColorBot: '#f15a22',
      messageIconBackgroundBot: '#f15a22',
      /* --------Footer-------- */
      inputColor: '#ffffff',
      inputBorderColor: '#8795a6',
      inputTextColor: 'rgba(0, 0, 0, 0.87)',
      inputPlaceholderColor: 'rgba(0, 0, 0, 0.38)',
      inputCancelButtonColor: '#f15a22',
      sendButtonIconColor: '#f15a22',
      sendButtonIconDisabledColor: '#9e9e9e',
      footerBackground: 'initial',
      footerBorderColor: 'transparent',
      /* --------Components-------- */
      /* Button */
      fieldColor: '#000000',
      fieldBackground: '#FFFFFF',
      fieldBorderColor: '#f15a22',
    },
    dark: {
      /* --------Bot icon button-------- */
      iconMessageBackground: '#39C1DF',
      iconMessageColor: '#ffffff',
      iconBackground: '#39C1DF',
      /* --------ChatWindow-------- */
      bodyBackground: '#F5F8FF',
      windowBorderColor: 'none',
      offlineMassageColor: 'aliceblue',
      offlineMassageBackgroundColor: '#b52424',
      /* --------Scroll-------- */
      scrollBackground: '#efefef',
      scrollThumbBackground: '#b5b7bd',
      /* --------Header-------- */
      headerColor: '#ffffff',
      headerBackground: '#000000',
      headerBorderColor: 'transparent',
      /* --------Date-------- */
      dateBackground: 'black',
      dateText: 'white',
      /* --------Body-------- */
      typingDotBackgroundColor: '#6eaad8',
      typingTextColor: '#6eaad8',
      /* --------User message-------- */
      userTimeColor: '#021d61',
      messageColor: 'white',
      messageBackground: '#021D61',
      messageBorderColor: 'transparent',
      messageIconBackground: '#021D61',
      /* --------Bot message-------- */
      linkColor: '#021D61',
      clickedOptionColor: '',
      messageColorBot: '#FFFFFF',
      botNameTimeColor: '#39C1DF',
      messageBackgroundBot: '#39C1DF',
      messageBorderColorBot: '#39C1DF',
      messageIconBackgroundBot: '#39C1DF',
      /* --------Footer-------- */
      inputColor: '#c6e3fa',
      inputBorderColor: '#8795a6',
      inputTextColor: 'rgba(0, 0, 0, 0.87)',
      inputPlaceholderColor: 'rgba(0, 0, 0, 0.38)',
      inputCancelButtonColor: 'gray',
      sendButtonIconColor: 'gray',
      sendButtonIconDisabledColor: '#9e9e9e',
      footerBackground: 'initial',
      footerBorderColor: 'transparent',
      /* --------Components-------- */
      /* Button */
      fieldColor: '#000000',
      fieldBackground: '#FFFFFF',
      fieldBorderColor: '#39C1DF',
    },
  },
  locale: 'en',
  locales: {
    en: {
      botName: 'Agent',
      headerText: 'Assistant',
      inputPlaceholder: 'Write your question...',
      iconMessageText: 'Need help?',
    },
    ru: {
      botName: 'Агент',
      inputPlaceholder: 'Напишите свой вопрос...',
      headerText: 'Ассистент',
      iconMessageText: 'Нужна помощь?',
    },
    // es: {
    //   botName: 'Agent',
    //   inputPlaceholder: 'Skribu la mesaĝon...',
    //   headerText: 'La asistanto de Umarketoj',
    //   iconMessageText: 'Ayuda con el depósito?',
    // },
    // de: {
    //   botName: 'Agent',
    //   inputPlaceholder: 'Schrijf het bericht...',
    //   headerText: 'De Umarkets-assistent',
    //   iconMessageText: 'Hilfe bei der Einzahlung?',
    // },
    // pt: {
    //   botName: 'Agent',
    //   inputPlaceholder: 'Escreva a mensagem...',
    //   headerText: 'O assistente do Umarkets',
    //   iconMessageText: 'Ajuda com depósito?',
    // },
  },
};
