import React from 'react';
import useTheme from '@mui/styles/useTheme';
import { Theme } from '@mui/material';
import fastDeepEqual from 'fast-deep-equal';
import { useSelector, createSelector } from '@wpa/redux-utils';

import { setEnabled } from '../../store/rootSlice';
import { MainChatIconContainer, IconWrapper, Icon, Message } from './styled';

const mainChatIconSelector = createSelector(['app', 'chatIconAlignment']);

function MainChatIcon({ iconMessageText }: { iconMessageText?: string }) {
  const { custom, customImages, typography } = useTheme<Theme>();

  const chatIconAlignment = useSelector(mainChatIconSelector, fastDeepEqual);

  return (
    <MainChatIconContainer
      horizontalAlignmentType={chatIconAlignment.horizontalAlignmentType}
      verticalAlignmentType={chatIconAlignment.verticalAlignmentType}
      horizontalAlignment={chatIconAlignment.horizontalAlignment}
      verticalAlignment={chatIconAlignment.verticalAlignment}
      className="sbu-Chat-icon"
    >
      {iconMessageText ? (
        <Message
          fontFamily={typography?.fontFamily}
          iconMessageColor={custom.iconMessageColor}
          iconMessageBackground={custom.iconMessageBackground}
          horizontalAlignmentType={chatIconAlignment.horizontalAlignmentType}
        >
          {iconMessageText}
        </Message>
      ) : null}
      <IconWrapper iconBackground={custom.iconBackground} onClick={() => setEnabled(true)}>
        <Icon src={customImages.iconUrl} />
      </IconWrapper>
    </MainChatIconContainer>
  );
}

export const MemoMainChatIcon = React.memo(MainChatIcon);
