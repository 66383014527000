import React from 'react';
import { SetAnimationConfig } from '../../../types';

type FakeButtonProps = {
  classes: Record<string, string>;
  text: string;
  setAnimationConfig: SetAnimationConfig;
};

export function FakeButton({ classes, text, setAnimationConfig }: FakeButtonProps): JSX.Element {
  const fakeButtonRef = React.useRef<HTMLDivElement | null>(null);

  React.useLayoutEffect(() => {
    if (fakeButtonRef.current) {
      setAnimationConfig((prev) => ({
        ...prev,
        fakeBtnWidth: fakeButtonRef.current?.offsetWidth,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.fakeButton} ref={fakeButtonRef}>
      <div className={classes.container}>
        <div className={classes.bubble}>
          <div className={classes.bubbleMessage}>
            <span>{text}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
