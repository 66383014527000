import { Size, Alignment } from '../types';

export function getAlignmentPosition(
  alignment: Alignment | undefined,
  currentSize: Size,
  windowCurrentWidth: number,
  windowCurrentHeight: number,
): { chatWindowBottomPosition: number; chatWindowRightPosition: number } {
  if (!alignment) {
    return { chatWindowBottomPosition: 20, chatWindowRightPosition: 20 };
  }

  const { verticalAlignment, horizontalAlignment, verticalAlignmentType, horizontalAlignmentType } = alignment;

  const rightPosition =
    horizontalAlignmentType !== 'left'
      ? horizontalAlignment || 20
      : windowCurrentWidth - (horizontalAlignment || 20) - currentSize.width;
  const bottomPosition =
    verticalAlignmentType !== 'top'
      ? verticalAlignment || 95
      : windowCurrentHeight - (verticalAlignment || 20) - currentSize.height;
  return { chatWindowBottomPosition: bottomPosition, chatWindowRightPosition: rightPosition };
}
