import React from 'react';
import root from '../ReactShadow/emotion';

type ReactShadowRoot = React.ComponentType<React.HTMLProps<HTMLElement>> | string;
type ShadowDomContainerProps = { children: JSX.Element };

export function ShadowDomContainer({ children }: ShadowDomContainerProps): JSX.Element {
  let Root: ReactShadowRoot = 'div';

  if (document.head.createShadowRoot || !!document.head.attachShadow) {
    // @ts-ignore
    Root = root.div;
  }

  return <Root id="shadow-host">{children}</Root>;
}
