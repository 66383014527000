import React, { useState, useEffect, forwardRef, MutableRefObject } from 'react';
import { createPortal } from 'react-dom';

import { useEnsuredForwardedRef } from '../useEnsuredForwardedRef';
import { handleError, Context } from '../utils';

function ShadowContent({ root, children = null }) {
  return createPortal(children, root);
}

type Props = {
  mode: ShadowRootMode;
  delegatesFocus: boolean;
  styleSheets: typeof CSSStyleSheet;
  ssr: boolean;
};

export default function create(options) {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return forwardRef<any, Props>((props, ref) => {
    const { mode = 'open', delegatesFocus = false, styleSheets = [], ssr = false, children, ...otherProps } = props;
    const node = useEnsuredForwardedRef<any>(ref as MutableRefObject<any>);
    const [root, setRoot] = useState(null);
    const key = `node_${mode}${delegatesFocus}`;

    useEffect(() => {
      if (node.current) {
        try {
          let newRoot;

          if (typeof ref === 'function') {
            ref(node.current);
          }

          if (ssr) {
            newRoot = node.current.shadowRoot;
            setRoot(newRoot);
          } else {
            newRoot = node.current.attachShadow({ mode, delegatesFocus });

            if (styleSheets.length > 0) {
              newRoot.adoptedStyleSheets = styleSheets;
            }

            setRoot(newRoot);
          }
        } catch (error) {
          handleError({ error, styleSheets, root });
        }
      }
    }, [delegatesFocus, mode, node, ref, root, ssr, styleSheets]);

    return (
      <options.tag key={key} ref={node} {...otherProps}>
        {(root || ssr) && (
          <Context.Provider value={root}>
            {ssr ? (
              // @ts-ignore
              <template shadowroot="open">{options.render({ root, ssr, children })}</template>
            ) : (
              <ShadowContent root={root}>{options.render({ root, ssr, children })}</ShadowContent>
            )}
          </Context.Provider>
        )}
      </options.tag>
    );
  });
}
