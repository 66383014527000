import { differenceInHours } from 'date-fns';
import { SENDER_TYPE } from '../constants';

const proxyLink = 'https://rlp-proxy.herokuapp.com/v2?url=';

async function getImageSize(image) {
  const result = {
    width: undefined,
    height: undefined,
  };
  try {
    if (image) {
      // const start = Date.now();
      return await new Promise((resolve, reject) => {
        const sandboxImg = new Image();
        sandboxImg.crossOrigin = undefined;
        sandboxImg.onload = function () {
          resolve({ width: this.width, height: this.height });
        };
        sandboxImg.onerror = function () {
          reject();
        };
        sandboxImg.src = image;
      });
      // console.log(`(${Date.now() - start} ms)`);
    }
    return result;
  } catch (e) {
    console.error(e);
    return result;
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function messageMapper({ type, responseText, responseImageURL, responseVideoURL }) {
  let linkForGeneratePreview = null;
  let image = null;
  let componentData;
  let topImage = true;
  const imageSize = { width: undefined, height: undefined };

  switch (type) {
    case 'text': {
      const parser = new DOMParser();
      const doc = parser.parseFromString(responseText, 'text/html');
      const domImg = doc.querySelector('img');
      // const link = doc.querySelector('a');
      const link = null;

      if (link) {
        linkForGeneratePreview = link.href;
        link.setAttribute('title', link.href);
        link.innerText = link.href;
      }

      let html = '';

      if (domImg && domImg.src !== window.location.href) {
        const cloneImg = domImg.cloneNode(true);
        // const tagA = document.createElement('a');
        // tagA.setAttribute('href', cloneImg.src);
        // tagA.setAttribute('target', '_blank');
        // tagA.appendChild(cloneImg);
        const span = document.createElement('span');
        domImg.replaceWith(span);
        image = cloneImg.src;
        topImage = false;
        const result = await getImageSize(image);
        imageSize.width = result.width;
        imageSize.height = result.height;
      }

      if ((domImg && domImg.src !== window.location.href) || link) {
        doc.body.childNodes.forEach((el) => {
          const serializer = new XMLSerializer();
          const str = serializer.serializeToString(el);
          html = html.concat('', str);
        });
      }

      if (linkForGeneratePreview) {
        try {
          const meta = await fetch(proxyLink + linkForGeneratePreview)
            .then((res) => res.json())
            .then((res) => res.metadata);
          image = meta.image || null;

          const result = await getImageSize(image);
          imageSize.width = result.width;
          imageSize.height = result.height;
        } catch (err) {
          console.error(err);
          console.error('No metadata could be found for the given URL.');
        }
      }

      componentData = html || responseText;
      break;
    }
    case 'image':
      componentData = decodeURIComponent(responseImageURL);
      break;
    case 'video':
      componentData = responseVideoURL;
      break;
    default:
      componentData = 'Ooops something went wrong';
  }

  return { componentData, image, imageSize, mapped: true, topImage };
}

function getDuration(momentA, momentB) {
  return differenceInHours(new Date(momentA), new Date(momentB));
}

export function mapPositionMessages(messages) {
  return messages.map((item, idx, arr) => {
    const previous = arr[idx - 1];
    const current = item;
    const next = arr[idx + 1];
    const isMine = current.type === SENDER_TYPE.user;

    let prevBySameAuthor = false;
    let nextBySameAuthor = false;
    let startsSequence = true;
    let endsSequence = true;

    if (previous) {
      prevBySameAuthor = previous.type === current.type;

      if (prevBySameAuthor && getDuration(current.data.time, previous.data.time) < 1) {
        startsSequence = false;
      }
    }

    if (next) {
      nextBySameAuthor = next.type === current.type;

      if (nextBySameAuthor && getDuration(next.data.time, current.data.time) < 1) {
        endsSequence = false;
      }
    }

    return { ...item, isMine, startsSequence, endsSequence };
  });
}
