/* eslint-disable no-param-reassign */
import { original } from 'immer';
import type { Action, ReducerCases, ReducerCase } from '@wpa/redux-utils';
import { dispatch, createSlice, bindActionCreators } from '@wpa/redux-utils';
import pipe from 'lodash/fp/pipe';
import { v4 as uuidV4 } from 'uuid';

import { ChatConfig, Size, History, Message, Position, State, MarkerEvent } from '../types';
import { getIPad13, isMobileAndTabletType } from '../utils/device-detect';
import { messageMapper, mapPositionMessages } from '../utils/messageMapper';
import { getSupportedLang } from '../utils/tools';
import { getAlignmentPosition } from './helpers';
import { reduceAsync, andThen, andCatch } from '../lodash-fp-ex';
import {
  SENDER_TYPE,
  CHAT_HISTORY_KEY,
  IS_ENABLED_KEY,
  CLICKED_OPTIONS_KEY,
  IS_FULLSCREEN_KEY,
  USER_ID_KEY,
  CURRENT_POSITION_KEY,
  CURRENT_SIZE_KEY,
} from '../constants';
import { AccuracyQnAMarker } from '../enums/marker.enum';

function getOrUpdateCachedValue(key, fallback) {
  const storageItem = window.WidgetCacheStorage.getItem(key);
  let result = fallback;

  if (storageItem !== null) {
    result = storageItem;
  } else {
    window.WidgetCacheStorage.setItem(key, fallback);
  }

  return result;
}

type Params = { chatConfig: ChatConfig; locale?: string };

export const initialState = async (params: Params): Promise<State> => {
  const { chatConfig, locale } = params;
  const { isEnabled, isFullScreen, chatWindowAlignment, locales, withThoughts } = chatConfig;

  const randomUserID = Math.random().toString(36).substring(7);
  const chatLang = getSupportedLang(Object.keys(locales), locale, chatConfig.locale);
  const localizedTexts = locales[chatLang];
  const windowCurrentWidth = window.innerWidth;
  const windowCurrentHeight = window.innerHeight;
  const defaultChatSize = { width: 350, height: 550 };
  const position = getAlignmentPosition(chatWindowAlignment, defaultChatSize, windowCurrentWidth, windowCurrentHeight);
  const defaultChatPosition = { bottom: position.chatWindowBottomPosition, right: position.chatWindowRightPosition };

  const isMobile = isMobileAndTabletType() || !!getIPad13();
  // const asyncPipe = createAsyncPipe<History, History>();
  // const mapHistory = async (promise, item) => {
  //   if (item.type === SENDER_TYPE.bot && !item.data.mapped) {
  //     const { componentData, image, imageSize, mapped, topImage } = await messageMapper(item.data);
  //
  //     return promise.then(async (last) => [
  //       ...last,
  //       {
  //         type: item.type,
  //         data: {
  //           ...item.data,
  //           componentData,
  //           image,
  //           imageSize,
  //           mapped,
  //           topImage,
  //         },
  //       },
  //     ]);
  //   }
  //
  //   return promise.then(async (last) => [...last, item]);
  // };
  // const asyncWaterfall = asyncPipe(reduce(mapHistory, Promise.resolve([])), (history) => mapPositionMessages(history));
  //
  // const messagesHistory = await asyncWaterfall(getOrUpdateCachedValue(SBU_CHAT_HISTORY, []));

  const asyncMapper = async (item) => {
    if (item.type === SENDER_TYPE.bot && !item.data.mapped) {
      const mappedMessage = await messageMapper(item.data);

      return {
        type: item.type,
        data: { ...item.data, ...mappedMessage },
      };
    }

    return item;
  };

  const imageMapper = reduceAsync(async (accP, item) => {
    const acc = await accP;
    const nextVal = await asyncMapper(item);
    acc.push(nextVal);

    return acc;
  }, []);

  const messagesHistory = await pipe(
    imageMapper,
    andThen(mapPositionMessages),
    andCatch((error) => {
      console.error(error);

      return [];
    }),
  )(getOrUpdateCachedValue(CHAT_HISTORY_KEY, []));

  const unreadMessagesCount = messagesHistory.filter(({ data }) => data.unread).length;

  return {
    ...chatConfig,
    withThoughts,
    selectedMessage: null,
    userID: getOrUpdateCachedValue(USER_ID_KEY, randomUserID),
    isMobile,
    isTyping: false,
    isUserMessageLast: false,
    botMessageGroupStartIndex: 0,
    isEnabled: isEnabled !== undefined ? isEnabled : getOrUpdateCachedValue(IS_ENABLED_KEY, false),
    isFullScreen: isFullScreen !== undefined ? isFullScreen : getOrUpdateCachedValue(IS_FULLSCREEN_KEY, false),
    currentPosition: getOrUpdateCachedValue(CURRENT_POSITION_KEY, defaultChatPosition),
    currentSize: getOrUpdateCachedValue(CURRENT_SIZE_KEY, defaultChatSize),
    clickedOptionsHistory: getOrUpdateCachedValue(CLICKED_OPTIONS_KEY, []),
    messagesHistory,
    unreadMessagesCount,
    windowCurrentWidth,
    windowCurrentHeight,
    localizedTexts,
  };
};

type Cases<S> = {
  setSelectedMessage: ReducerCase<S, Action<Message>>;
  setWindowSize: ReducerCase<S, Action<Size>>;
  setIsTyping: ReducerCase<S, Action<boolean>>;
  setIsMobile: ReducerCase<S, Action<boolean>>;
  setEnabled: ReducerCase<S, Action<boolean>>;
  toggleFullScreen: ReducerCase<S, Action<boolean>>;
  setFullScreen: ReducerCase<S, Action<boolean>>;
  setCurrentPosition: ReducerCase<S, Action<Position>>;
  setCurrentSize: ReducerCase<S, Action<Size>>;
  setMessagesHistory: ReducerCase<S, Action<Message>>;
  patchHistoryMessage: ReducerCase<S, Action<MarkerEvent>>;
};

const { actions, reducer } = createSlice<State, ReducerCases<State, Action<any>, Cases<State>>>({
  name: '@app',
  withActionPrefix: false,
  reducerCases: {
    setSelectedMessage: (draft, { payload }) => {
      draft.selectedMessage = payload;
    },
    setWindowSize(draft, { payload }) {
      draft.windowCurrentWidth = payload.width;
      draft.windowCurrentHeight = payload.height;
    },
    setIsTyping(draft, { payload }) {
      draft.isTyping = payload;
    },
    setIsMobile(draft, { payload }) {
      draft.isMobile = payload;
    },
    setEnabled: (draft, { payload }) => {
      window.WidgetCacheStorage.setItem(IS_ENABLED_KEY, payload);
      draft.isEnabled = payload;
    },
    toggleFullScreen: (draft) => {
      window.WidgetCacheStorage.setItem(IS_FULLSCREEN_KEY, !draft.isFullScreen);
      draft.isFullScreen = !draft.isFullScreen;
    },
    setFullScreen: (draft, { payload }) => {
      window.WidgetCacheStorage.setItem(IS_FULLSCREEN_KEY, payload);
      draft.isFullScreen = payload;
    },
    setCurrentPosition: (draft, { payload }) => {
      const newPosition = { ...draft.currentPosition, ...payload };
      window.WidgetCacheStorage.setItem(CURRENT_POSITION_KEY, newPosition);
      draft.currentPosition = newPosition;
    },
    setCurrentSize: (draft, { payload }) => {
      window.WidgetCacheStorage.setItem(CURRENT_SIZE_KEY, payload);
      draft.currentSize = payload;
    },
    setMessagesHistory: (draft, { payload }) => {
      const history: History = window.WidgetCacheStorage.getItem(CHAT_HISTORY_KEY) || [];
      let isUserMessageLast = false;
      let isStartBotMessageGroup = false;

      if (payload.type === SENDER_TYPE.user) {
        isUserMessageLast = true;
      }

      if (payload.data.botMessageReplyId) {
        const index = history.findIndex((item) => item.data.id === payload.data.botMessageReplyId);

        if (Number.isInteger(index)) {
          history[index].data.replyActionIndex = payload.data.replyActionIndex;
          history[index].data.disabled = true;
          draft.messagesHistory[index].data.replyActionIndex = payload.data.replyActionIndex;
          draft.messagesHistory[index].data.disabled = true;
        }
      }

      if (draft.isUserMessageLast) {
        isStartBotMessageGroup = true;
      }

      draft.isUserMessageLast = isUserMessageLast;

      if (isStartBotMessageGroup) {
        draft.botMessageGroupStartIndex = draft.messagesHistory.length;
      }

      if (payload.type === SENDER_TYPE.bot || payload.type === SENDER_TYPE.user) {
        const unboxedHistory = original(draft.messagesHistory);
        if (unboxedHistory) {
          const hist = mapPositionMessages([...unboxedHistory, payload]);
          window.WidgetCacheStorage.setItem(CHAT_HISTORY_KEY, [...hist]);
          draft.messagesHistory = [...hist];
        }
      } else {
        window.WidgetCacheStorage.setItem(CHAT_HISTORY_KEY, [...history, payload]);
        draft.messagesHistory.push(payload);
      }
    },
    patchHistoryMessage(draft, { payload }) {
      if (payload) {
        const unboxedHistory = original(draft.messagesHistory) || [];

        const isIncorrect = payload.marker === AccuracyQnAMarker.Incorrect;
        let cloneUpdatedMessage;
        let originalQuestion;

        let mappedHistory = unboxedHistory.map((message, idx, array) => {
          if (message.data.incomingEventId === payload.incomingEventId && message.data.id === payload.messageId) {
            if (isIncorrect) {
              cloneUpdatedMessage = message;
              originalQuestion = message?.originalQuestion || array?.[idx - 1]?.data?.inputText;
            }

            return { ...message, marker: payload.marker };
          }

          return message;
        });

        if (isIncorrect) {
          cloneUpdatedMessage = {
            ...cloneUpdatedMessage,
            originalQuestion,
            data: {
              ...cloneUpdatedMessage.data,
              id: uuidV4(),
              time: new Date(),
              componentData: payload.newAnswer,
            },
          };

          const infoMessage = {
            ...cloneUpdatedMessage,
            hideMarkingButtons: true,
            data: {
              ...cloneUpdatedMessage.data,
              id: uuidV4(),
              time: new Date(),
              componentData: `Here is a new answer to your question: ${originalQuestion}`,
              responseText: '',
            },
          };

          mappedHistory = [...mappedHistory, infoMessage, cloneUpdatedMessage];
        }

        window.WidgetCacheStorage.setItem(CHAT_HISTORY_KEY, mappedHistory);

        draft.messagesHistory = mappedHistory;
      }
    },
  },
});

const {
  setWindowSize,
  setIsTyping,
  setIsMobile,
  setEnabled,
  setSelectedMessage,
  toggleFullScreen,
  setFullScreen,
  setCurrentPosition,
  setCurrentSize,
  setMessagesHistory,
  patchHistoryMessage,
} = bindActionCreators(actions, dispatch);

export {
  reducer as rootReducer,
  setWindowSize,
  setSelectedMessage,
  setIsTyping,
  setIsMobile,
  setEnabled,
  toggleFullScreen,
  setFullScreen,
  setCurrentPosition,
  setCurrentSize,
  setMessagesHistory,
  patchHistoryMessage,
};
