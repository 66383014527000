/* eslint-disable class-methods-use-this,@typescript-eslint/no-empty-function,@typescript-eslint/ban-ts-comment */
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import type { Merge } from 'ramda';
import { mergeDeepLeft, mergeDeepRight, omit, pipe, propOr } from 'ramda';
import env from '@beam-australia/react-env';

import type { ChatConfig } from './types';
import { Widget } from './Widget';
import { BOT_CONTAINER_ID, USER_ID_KEY } from './constants';
import { initialState } from './store/rootSlice';
import { defaultChatConfig } from './defaultChatConfig';
import { WidgetCacheStorage } from './WidgetCacheStorage';

type PartialChatConfig = Partial<ChatConfig>;

export type InitConfig = {
  key: string;
  clientData: string | number;
  locale?: string;
  externalConfig?: PartialChatConfig;
};

type ConfigFromServer = { customizationConfigObject?: PartialChatConfig; parameters?: string[] };

function hubConnectionFactory() {
  let connection;
  const reconnect = new Array(8640).fill(10000);
  try {
    connection = new HubConnectionBuilder()
      .withUrl(env('HUB_CONNECTION_URL') || '')
      .configureLogging(process.env.NODE_ENV === 'development' ? LogLevel.Debug : LogLevel.Critical)
      .withAutomaticReconnect(reconnect)
      .build();
  } catch (e) {
    console.error('SmartBot hubConnectionFactory error => ', e);
  }

  return connection;
}

export class Initialization {
  readonly #version: string | undefined;

  #initConfig: (InitConfig & { chatConfig?: ChatConfig }) | undefined;

  #hubConnection: HubConnection | undefined;

  #cacheStorage: WidgetCacheStorage | undefined;

  constructor() {
    this.#version = process.env.APP_VERSION;
  }

  public get version() {
    return this.#version;
  }

  public async init(initConfig: InitConfig): Promise<void> {
    if (Initialization.isWidgetExist()) {
      this.removeChat();
    }

    this.checkLoadedScriptsCount();

    if (!this.#hubConnection) {
      this.initHubConnection();
    }

    const botContainer = document.createElement('div');
    botContainer.setAttribute('id', BOT_CONTAINER_ID);

    if (initConfig.externalConfig?.appendTo && document.getElementById(initConfig.externalConfig.appendTo)) {
      const appendTo = document.getElementById(initConfig.externalConfig.appendTo) as HTMLElement;
      appendTo.appendChild(botContainer);
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.appendChild(botContainer);
    }

    this.#cacheStorage = WidgetCacheStorage.createInstance({
      driver: initConfig.externalConfig?.cacheStorage,
      keyPrefix: initConfig.externalConfig?.userID,
    });

    const randomUserID = Math.random().toString(36).substring(7);

    this.#cacheStorage?.setItem(USER_ID_KEY, initConfig.externalConfig?.userID || randomUserID);

    window.WidgetCacheStorage = this.#cacheStorage;

    await this.start(initConfig, botContainer);
  }

  public onLogout(): void {
    this.#cacheStorage?.clear();

    this.removeChat();
  }

  public getShowingState(): string {
    const root = document.getElementById(BOT_CONTAINER_ID);

    if (root && root.firstChild && root.firstElementChild?.classList.contains('sbu-Chat-window')) {
      return 'opened';
    }

    return 'closed';
  }

  public hideChatWindow(): void {}

  public showChatWindow(): void {}

  private initHubConnection(): void {
    this.#hubConnection = hubConnectionFactory();
  }

  private destroyHubConnection(): void {
    if (this.#hubConnection) {
      this.#hubConnection.stop().then(() => {
        this.#hubConnection = undefined;
      });
      // this.#hubConnection = undefined;
    }
  }

  private static isWidgetExist(): boolean {
    return !!document.getElementById(BOT_CONTAINER_ID);
  }

  private static async getConfig(key: string): Promise<ConfigFromServer> {
    const res = await fetch(`${env('CONFIG_SERVICE_URL')}/${key}`);
    return res.json();
  }

  private async start(initConfig: InitConfig, botContainer: HTMLDivElement): Promise<void> {
    const { locale, clientData, key, externalConfig = {} } = initConfig;

    try {
      const config = await Initialization.getConfig(key);
      const clientDataBookmark = Array.isArray(config.parameters)
        ? `${clientData}_${(config.parameters || []).join('_')}`
        : String(clientData);

      const chatConfig = pipe<
        [ConfigFromServer | undefined],
        PartialChatConfig,
        Merge<PartialChatConfig, ChatConfig, 'deep'>,
        Merge<Record<string, any>, ChatConfig, 'deep'>
      >(
        propOr({}, 'customizationConfigObject'),
        mergeDeepRight(defaultChatConfig),
        mergeDeepLeft(omit(['isCopyrightVisible'], externalConfig)),
      )(config);

      this.#initConfig = { locale, clientData, key, chatConfig };

      const initState = await initialState({ chatConfig, locale });

      if (process.env.NODE_ENV === 'development') {
        console.log('initState', initState);
      }

      render(
        <Widget
          initialState={initState}
          clientData={clientDataBookmark}
          channelKey={key}
          hubConnection={this.#hubConnection}
        />,
        botContainer,
      );
    } catch (err) {
      console.error('SmartBot fetch config error => ', err);
      // setTimeout(() => this.start(initConfig, userID, botContainer), 5000);
    }
  }

  private removeChat(): void {
    if (this.#hubConnection) {
      this.destroyHubConnection();
    }

    const chat = document.getElementById(BOT_CONTAINER_ID);

    if (chat) {
      const isNodeUnmounted = unmountComponentAtNode(chat);
      console.info('SmartBot chat unmounted => ', isNodeUnmounted);
      chat.remove();
    }
  }

  private checkLoadedScriptsCount(): void {
    const url = Array.from(document.getElementsByTagName('script'))
      .map((item) => item.attributes.getNamedItem('src')?.value)
      .filter((item) => String(item).search(/smartbot-chat/g) !== -1);

    const scriptsCount = url.length;

    if (scriptsCount > 1) {
      console.error(
        `There are ${scriptsCount} loaded Smart-Bot scripts to DOM! Please, decrease it to 1 to avoid unexpected situations.`,
      );
    }
  }

  // private getParentContainer(): ChildNode | null {
  //   const root = document.getElementById(BOT_CONTAINER_ID);
  //
  //   if (root && root.firstElementChild && root.firstElementChild?.classList.contains('sbu-Chat-window')) {
  //     return root.firstElementChild;
  //   }
  //
  //   return null;
  // }
}
