import React from 'react';

import StyledVideo from './StyledVideo';

type VideoProps = {
  src: string;
  isFullScreen: boolean;
};

export function Video({ src, isFullScreen }: VideoProps): JSX.Element {
  return (
    <StyledVideo
      isFullScreen={isFullScreen}
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}
