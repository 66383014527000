import { useContext } from 'react';
import { decamelize } from 'humps';

import create from './core';
import * as utils from './utils';

const tags = new Map();

export function useShadowRoot() {
  return useContext(utils.Context);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function defaultRender({ root, children }) {
  return children;
}

export function createProxy(target = {}, id = 'core', render = defaultRender) {
  return new Proxy(target, {
    get: function get(_, name: string) {
      const tag = decamelize(name, { separator: '-' });
      const key = `${id}-${tag}`;

      if (!tags.has(key)) {
        tags.set(key, create({ tag, render }));
      }

      return tags.get(key);
    },
  });
}
