export function debounce(fun, waitMs, immediate?) {
  let timeout;
  return function deb(...args) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) {
        fun.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, waitMs);
    if (callNow) {
      fun.apply(context, args);
    }
  };
}

export const throttleRaf = (f) => {
  let token = null;
  let lastArgs = null;

  const invoke = () => {
    // @ts-ignore
    f(...lastArgs);
    token = null;
  };

  const result = (...args) => {
    // @ts-ignore
    lastArgs = args;
    if (!token) {
      // @ts-ignore
      token = requestAnimationFrame(invoke);
    }
  };

  result.cancel = () => token && cancelAnimationFrame(token);

  return result;
};

export const throttle = (func, ms) => {
  let isThrottled = false;
  let savedArgs;
  let savedThis;

  function wrapper() {
    if (isThrottled) {
      // eslint-disable-next-line prefer-rest-params
      savedArgs = arguments;
      // @ts-ignore
      savedThis = this;
      return;
    }

    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    func.apply(this, arguments);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        // eslint-disable-next-line no-multi-assign
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
};

export function getSupportedLang(locales: Array<string>, lang: string | undefined, defaultLocale: string): string {
  const isSupported = locales.find((language) => language === lang);

  if (isSupported && lang) {
    return lang;
  }

  console.warn('SmartBot => ', `${lang} don't support. Installed default lang "${defaultLocale}"`);

  return defaultLocale;
}

export const isoToLocaleDate = (date, isResetTime) => {
  const localDate = new Date(date);

  if (isResetTime) {
    return new Date(localDate.setHours(0, 0, 0, 0));
  }

  return localDate;
};

/**
 * @param {Date} firstDate
 * @param {Date} secondDate
 */
export const isCompareDate = (firstDate: Date, secondDate: Date) => firstDate.getTime() === secondDate.getTime();

export function hideKeyboard() {
  const body = document.getElementsByTagName('body')[0];
  const activeElement = document.activeElement?.tagName;

  if (activeElement === 'TEXTAREA' || activeElement === 'INPUT') {
    // @ts-ignore
    document.activeElement?.blur();
  } else {
    body.focus();
  }

  body.style.width = String(window.innerWidth);
  body.style.height = String(window.innerHeight);
}
