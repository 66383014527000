import { createContext } from 'react';

export const Context = createContext(null);

export function handleError({ error, styleSheets, root }) {
  if (error.name === 'NotSupportedError') {
    if (styleSheets.length > 0) {
      // eslint-disable-next-line no-param-reassign
      root.adoptedStyleSheets = styleSheets;
    }
  } else {
    throw error;
  }
}
