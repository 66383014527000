import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const DateWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '15px',
  alignItems: 'center',
});

export const DateText = styled(Typography, { name: 'DateText', slot: 'Root' })(({ theme }) => ({
  backgroundColor: `${theme.custom.dateBackground}`,
  borderRadius: '10px',
  padding: '0.2em 0.7em',
  textAlign: 'center',
  color: `${theme.custom.dateText}`,
  // opacity: 0.3,
  boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)',
  // flex-basis: 100%;
}));
