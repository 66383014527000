import { styled } from '@mui/material/styles';

export const FooterWrapper = styled('div', { name: 'FooterWrapper', slot: 'Root' })(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 12px 0 12px',
  boxSizing: 'border-box',
  background: `${theme.custom.footerBackground}`,
  border: `1px solid ${theme.custom.footerBorderColor}`,
  borderBottomLeftRadius: `${theme.custom.windowBorderRadius}`,
  borderBottomRightRadius: `${theme.custom.windowBorderRadius}`,
}));

export const ButtonsWrapper = styled('div')({
  width: '100%',
  maxWidth: 1024,
  display: 'flex',
  marginTop: '8px',
  '& > div': {
    width: '100%',
    '&:last-child': {
      marginRight: 0,
    },
  },
});
