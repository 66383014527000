import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { Resizable as BaseResizable } from 're-resizable';
import { applySpec } from 'ramda';
import fastDeepEqual from 'fast-deep-equal';
import { useSelector, createSelector } from '@wpa/redux-utils';

import { Position, State, Size } from '../../types';
import { setCurrentSize, setCurrentPosition } from '../../store/rootSlice';

type WrapperResizableProps = {
  bounds: string;
  boundsByDirection: boolean;
  children: JSX.Element;
  enable: Record<string, boolean>;
  maxHeight: number;
  maxWidth: number;
  minHeight: number;
  minWidth: number;
  onResize: (e, dir, innerRef) => void;
  onResizeStart: (e, dir) => void;
  onResizeStop: (e, dir, innerRef, delta) => void;
  size: Size;
  style: Record<string, any>;
  className: string;
};

const WrapperResizable = React.forwardRef(
  ({ className, ...rest }: WrapperResizableProps, ref: any): JSX.Element => (
    // @ts-ignore
    <BaseResizable
      ref={(instance) => {
        if (instance && instance.resizable) {
          ref(instance.resizable);
        }
      }}
      className={className}
      {...rest}
    />
  ),
);
const ResizableStyled = styled(WrapperResizable)`
  z-index: 2147483647;
  background: transparent !important;
`;

function getStyles(params) {
  const { isFullScreen, isRightTopResize, isLeftBottomResize, currentPosition, width, height } = params;

  if (isFullScreen) {
    return {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };
  }

  return {
    position: 'fixed',
    width,
    height,
    right: isRightTopResize ? 'initial' : currentPosition.right,
    bottom: isLeftBottomResize ? 'initial' : currentPosition.bottom,
    left: isRightTopResize ? currentPosition.left : 'initial',
    top: isLeftBottomResize ? currentPosition.top : 'initial',
  };
}

const getMinSize = (windowCurrentWidth, windowCurrentHeight, currentSize) => {
  if (currentSize.width >= windowCurrentWidth && currentSize.height < windowCurrentHeight) {
    if (windowCurrentHeight > 768) {
      return { width: windowCurrentWidth, height: 670 };
    }

    return { width: windowCurrentWidth, height: 550 };
  }

  if (currentSize.width < windowCurrentWidth && currentSize.height >= windowCurrentHeight) {
    if (windowCurrentHeight > 768) {
      return { width: 400, height: windowCurrentHeight };
    }

    return { width: 350, height: windowCurrentHeight };
  }

  if (currentSize.width >= windowCurrentWidth && currentSize.height >= windowCurrentHeight) {
    if (windowCurrentHeight > 768) {
      return { width: windowCurrentWidth, height: windowCurrentHeight };
    }

    return { width: windowCurrentWidth, height: windowCurrentHeight };
  }

  // if (windowCurrentHeight > 768) {
  //   return {
  //     width: 400,
  //     height: 670,
  //   };
  // }

  return { width: 350, height: 550 };
};

const resizableSelector = applySpec({
  isFullScreen: createSelector(['app', 'isFullScreen']),
  currentSize: createSelector(['app', 'currentSize']),
  currentPosition: createSelector(['app', 'currentPosition']),
  windowCurrentWidth: createSelector(['app', 'windowCurrentWidth']),
  windowCurrentHeight: createSelector(['app', 'windowCurrentHeight']),
});

type ResizableSelected = {
  isFullScreen: State['isFullScreen'];
  currentSize: State['currentSize'];
  currentPosition: State['currentPosition'];
  windowCurrentWidth: State['windowCurrentWidth'];
  windowCurrentHeight: State['windowCurrentHeight'];
};

type ResizableProps = {
  className: string;
  children: JSX.Element;
  style: Record<string, string>;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Resizable = React.forwardRef((props: ResizableProps, ref) => {
  const { children, className, style } = props;
  const { currentSize, windowCurrentWidth, windowCurrentHeight, currentPosition, isFullScreen } = useSelector<
    State,
    ResizableSelected
  >(resizableSelector, fastDeepEqual);

  const [isRightResize, setRightResize] = React.useState(false);
  const [isRightTopResize, setRightTopResize] = React.useState(false);
  const [isLeftBottomResize, setLeftBottomResize] = React.useState(false);

  React.useEffect(() => {
    const { right, bottom } = currentPosition;
    const width = currentSize?.width;
    const height = currentSize?.height;
    const negativeDeltaRight = windowCurrentWidth - (right + width);
    const negativeDeltaBottom = windowCurrentHeight - (bottom + height);
    const isMoveRight = negativeDeltaRight < 0;
    const isMoveBottom = negativeDeltaBottom < 0;

    if (!isFullScreen && (isMoveRight || isMoveBottom)) {
      setCurrentPosition({
        right: isMoveRight && right >= 0 ? right + negativeDeltaRight : right,
        bottom: isMoveBottom && bottom >= 0 ? bottom + negativeDeltaBottom : bottom,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen, windowCurrentHeight, windowCurrentWidth]);

  const onResizeHandler = React.useCallback((e, dir, innerRef) => {
    setCurrentSize({
      width: parseInt(innerRef.style.width, 10),
      height: parseInt(innerRef.style.height, 10),
    });
  }, []);

  const onResizeStartHandler = React.useCallback(
    (e, dir) => {
      window.startMousePosition = { x: e.pageX, y: e.pageY };
      const width = currentSize?.width;
      const height = currentSize?.height;
      const { right, bottom } = currentPosition;

      switch (dir) {
        case 'bottom':
        case 'right':
        case 'bottomRight':
          setRightResize(true);
          setCurrentPosition({
            ...currentPosition,
            top: windowCurrentHeight - bottom - height,
            left: windowCurrentWidth - right - width,
          });
          break;
        case 'topRight':
          setRightTopResize(true);
          setCurrentPosition({
            ...currentPosition,
            left: windowCurrentWidth - right - width,
          });
          break;
        case 'bottomLeft':
          setLeftBottomResize(true);
          setCurrentPosition({
            ...currentPosition,
            top: windowCurrentHeight - bottom - height,
          });
          break;
        default:
          setRightResize(false);
      }
    },
    [currentPosition, currentSize, windowCurrentHeight, windowCurrentWidth],
  );

  const onResizeStopHandler = React.useCallback(
    (e, dir, innerRef, delta) => {
      const { width, height } = delta;
      const { right, bottom } = currentPosition;
      let newPosition: Position;

      switch (dir) {
        case 'bottom':
        case 'right':
        case 'bottomRight':
          newPosition = { bottom: bottom - height, right: right - width };
          break;
        case 'topRight':
          newPosition = { bottom, right: right - width };
          break;
        case 'bottomLeft':
          newPosition = { bottom: bottom - height, right };
          break;
        default:
          newPosition = currentPosition;
          break;
      }
      setCurrentSize({
        width: parseInt(innerRef.style.width, 10),
        height: parseInt(innerRef.style.height, 10),
      });

      setCurrentPosition(newPosition);
      setRightResize(false);
      setRightTopResize(false);
      setLeftBottomResize(false);
    },
    [currentPosition],
  );

  const maxWidth = currentPosition.right <= 0 ? windowCurrentWidth + currentPosition.right : windowCurrentWidth;
  const maxHeight = currentPosition.bottom <= 0 ? windowCurrentHeight + currentPosition.bottom : windowCurrentHeight;
  const { width: minWidth, height: minHeight } = getMinSize(windowCurrentWidth, windowCurrentHeight, currentSize);

  React.useEffect(() => {
    setCurrentSize({ width: minWidth, height: minHeight });
  }, [minHeight, minWidth]);

  return (
    <ResizableStyled
      ref={ref}
      className={clsx('sbu-Resizable sbu-Chat-window', className)}
      bounds="window"
      boundsByDirection
      size={{
        width: isFullScreen ? windowCurrentWidth : currentSize?.width,
        height: isFullScreen ? windowCurrentHeight : currentSize?.height,
      }}
      style={{
        ...getStyles({
          isFullScreen,
          isRightTopResize: isRightResize || isRightTopResize,
          isLeftBottomResize: isRightResize || isLeftBottomResize,
          currentPosition,
          width: minWidth,
          height: minHeight,
          windowCurrentWidth,
          windowCurrentHeight,
        }),
        ...style,
      }}
      enable={{
        top: !isFullScreen,
        left: !isFullScreen,
        topLeft: !isFullScreen,
        bottomRight: !isFullScreen,
        bottom: !isFullScreen,
        right: !isFullScreen,
        topRight: !isFullScreen,
        bottomLeft: !isFullScreen,
      }}
      minWidth={minWidth}
      minHeight={minHeight}
      maxWidth={isFullScreen ? windowCurrentWidth : maxWidth}
      maxHeight={isFullScreen ? windowCurrentHeight : maxHeight}
      onResize={onResizeHandler}
      onResizeStop={onResizeStopHandler}
      onResizeStart={onResizeStartHandler}
    >
      {children}
      {/* {children({ */}
      {/*  width: isFullScreen ? windowCurrentWidth : currentSize?.width, */}
      {/*  height: isFullScreen ? windowCurrentHeight : currentSize?.height, */}
      {/* })} */}
    </ResizableStyled>
  );
});

export const ResizableMemo = React.memo(Resizable);
