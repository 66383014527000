/* eslint-disable no-await-in-loop,no-restricted-syntax */
import always from 'lodash/fp/always';
import attempt from 'lodash/fp/attempt';
import camelCase from 'lodash/fp/camelCase';
import cloneDeep from 'lodash/fp/cloneDeep';
import concat from 'lodash/fp/concat';
import cond from 'lodash/fp/cond';
import curry from 'lodash/fp/curry';
import entries from 'lodash/fp/entries';
import equals from 'lodash/fp/equals';
import every from 'lodash/fp/every';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flatMapDeep from 'lodash/fp/flatMapDeep';
import forEach from 'lodash/fp/forEach';
import forOwn from 'lodash/fp/forOwn';
import get from 'lodash/fp/get';
import has from 'lodash/fp/has';
import head from 'lodash/fp/head';
import identity from 'lodash/fp/identity';
import includes from 'lodash/fp/includes';
import indexOf from 'lodash/fp/indexOf';
import isArray from 'lodash/fp/isArray';
import isBoolean from 'lodash/fp/isBoolean';
import isEmpty from 'lodash/fp/isEmpty';
import isError from 'lodash/fp/isError';
import isFunction from 'lodash/fp/isFunction';
import isNaN from 'lodash/fp/isNaN';
import isNil from 'lodash/fp/isNil';
import isNumber from 'lodash/fp/isNumber';
import isObject from 'lodash/fp/isObject';
import isPlainObject from 'lodash/fp/isPlainObject';
import isString from 'lodash/fp/isString';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import reduce from 'lodash/fp/reduce';
import size from 'lodash/fp/size';
import snakeCase from 'lodash/fp/snakeCase';
import some from 'lodash/fp/some';
import T from 'lodash/fp/T';
import toNumber from 'lodash/fp/toNumber';
import upperFirst from 'lodash/fp/upperFirst';

/**
 * 대상 인자가 promise(thenable)인지 여부 조회
 *
 * @param {any} x 조회 대상
 * @return {boolean} 대상 인자가 promise(thenable)인지 여부
 */
const isPromise = (x: any) => isFunction(get('then', x)) && isFunction(get('catch', x));

/**
 * 대상 함수를 promise로 lift
 *
 * @param {function} fn 대상 합수
 * @param  {...any} args 함수 인자 목록
 * @returns {Promise<any>} Promise로 lift된 Promise 객체
 */
const fnPromisify = (fn: any, ...args: any[]) =>
  new Promise((resolve, reject) => {
    try {
      resolve(fn(...args));
    } catch (error) {
      reject(error);
    }
  });

/**
 * 대상 인자를 promise로 wrapping (lift)
 *
 * @param {any} a 대상 인자
 * @param {...any} args 대상 인자 a가 함수인 경우, 함수의 인자목록
 * @return {Promise<any>} Promise로 lift된 Promise 객채
 */
const promisify = (a: any, ...args: any[]) => {
  const _cond = cond([
    [isFunction, () => fnPromisify(a, ...args)],
    [isPromise, identity],
    [T, (_a) => Promise.resolve(_a)],
  ]);
  return _cond(a);
};

/**
 * promise가 또다른 promise를 resolve하는 경우, promise의 중첩을 제거하기 위한 helper 함수
 *
 * @param {Promise<any>} thenable Promise 객체
 * @return {Promise<any>} 중첩 제거된 Promise 객체
 */
const flatPromise = (thenable: any) => (isPromise(thenable) ? thenable.then((x: any) => flatPromise(x)) : thenable);

/**
 * lodash 형태의 promise then
 *
 * @param {(response) => any} fn 응답값 처리 callback
 * @param {Promise<any>} thenable resolve 대상 Promise 객체
 * @returns {Promise<any>} fullfilled 상태의 Promise 객체
 */
const then = curry((fn: any, thenable: any) => promisify(thenable).then(flatPromise(fn)));

/**
 * lodash 형태의 promise catch
 *
 * @param {(error) => never} fn error 처리 callback
 * @param {Promise<error>} thenable error를 resolve 하는 promise
 * @return {Promise<never>} error 상태의 Promise 객체
 */
const otherwise = curry((fn: any, thenable: any) => promisify(thenable).catch(flatPromise(fn)));

/**
 * lodash 형태의 promise finally
 *
 * @param {() => any} fn Promise 상태와 무관하게 실행되는 callback
 * @param {Promise<any>} thenable Promise 객체
 * @return {void} 반환값 없음
 */
const _finally = curry((fn: any, thenable: any) => promisify(thenable).finally(flatPromise(fn)));

/**
 * invert boolean
 * @param {any} x 대상
 * @return {boolean} not 연산된 값
 */
const not = (x: any) => !x;

/**
 * 대상이 비어있지 않은지 여부
 * (주의: 숫자타입은 항상 true를 반환)
 *
 * @param {any} a 대상
 * @returns {boolean} 비어 있는지 여부
 */
const isNotEmpty = pipe(isEmpty, not);

/**
 * 대상 인자를 boolean 타입으로 변환\
 *
 * @param {any} a 대상
 * @returns {boolean} 변환된 boolean 타입 값
 */
const toBool = (a: any) => !!a;

/**
 * 삼항식 helper 함수\
 * (isTrue가 true면 t(실행)반환, false면 f(실행)반환)
 *
 * @param {function} evaluator 대상인자가 true 인지여부 조회 함수 또는 boolean을 반환하는 함수
 * @param {function} trueHandler evaluator가 true를 반환하면,  실행되는 대상인자를 인자로 갖는 함수
 * @param {function} falseHandler evaluator가 false를 반환하면, 실행되는 대상인자를 인자로 갖는 함수
 * @param {function|any} a 대상인자
 * @returns {any} handler의 결과값
 */
const ternary = curry((evaluator: any, trueHandler: any, falseHandler: any, a: any) => {
  const executor = curry((t: any, f: any, _a: any, isTrue: any) =>
    // eslint-disable-next-line no-nested-ternary
    isTrue ? (isFunction(t) ? t(_a) : t) : isFunction(f) ? f(_a) : f,
  );
  const getEvaluator = (fn: any) => (isNil(fn) ? identity : fn);
  return executor(trueHandler, falseHandler, a, getEvaluator(evaluator)(a));
});

/**
 * a인자가 t타입인지 여부 조회
 * @param {any} t 조회 대상 type
 * @param {any} a 조회 대상
 * @returns {boolean} a인자가 t타입인지 여부
 */
const instanceOf = curry((t: any, a: any) => a instanceof t);

/**
 * 대상 문자열을 pascalcase 문자열로 변환
 * @param {string} a 대상 문자열
 * @returns {string} pascal case로 변환된 문자열
 */
const pascalCase = pipe(camelCase, upperFirst);

/**
 * (collection) map의 비동기 함수\
 * mapper 함수로 비동기 함수를 받아서 처리해준다.
 *
 * @param {(a) => Promise<any>} asyncMapper 비동기 mapper
 * @param {object|any[]} collection 대상 object 또는 array
 * @returns {Promise<any[]>} 결과 array를 resolve 하는 promise
 */
const mapAsync = curry(async (asyncMapper: any, collection: any) => {
  const composer = pipe(flatMapDeep(pipe(asyncMapper, promisify)), async (a) => Promise.all(a));
  return composer(collection);
});

/**
 * (collection) filter의 비동기 함수\
 * 필터함수로 비동기 함수를 받아서 처리해준다.
 *
 * @param {(a) => Promise<bool>} asyncFilter 비동기 필터
 * @param {object|any[]} collection 대상 object 또는 array
 * @returns {Promise<any[]>} 결과 array를 resolve하는 promise
 */
const filterAsync = curry(async (asyncFilter: any, arr: any) => {
  const composer = pipe(
    mapAsync(async (item: any) => ((await asyncFilter(item)) ? item : false)),
    then(filter(pipe(equals(false), not))),
  );
  return composer(arr);
});
/**
 * (collection) find의 비동기 함수
 * @param {(a) => Promise<bool>} asyncFn 비동기 필터
 * @param {object|any[]} collection 대상 object 또는 array
 * @returns {Promise<any>} 필터된 단일 결과를 resolve하는 promise
 */
const findAsync = curry(async (asyncFn: any, arr: any) => {
  const composer = pipe(
    mapAsync(asyncFn),
    then(indexOf(true)),
    then((idx: any) => get(`[${idx}]`, arr)),
    otherwise(always(null)),
  );
  return composer(arr);
});

/**
 * asyncFn의 시작은 await accPromise가 되어야 한다.\
 * 순차적으로 실행된다.\
 * (ex 300ms이 걸리는 5개의 promise가 있다면, 최소 1500ms+alpah의 시간이 소요된다.\
 * 상기의 mapAsync의 경우 300+alpah의 시간만 소요된다.(Promise.all과 Promise.resolve의 차이))
 *
 * @param {(acc, v) => Promise<any>} asyncFn 비동기 iterator
 * @param {Promise<any>} initAcc 초기 누적기를 반환하는 promise
 * @param {object|any[]} dest 대상 객체 또는 배열
 * @returns {Promise<any>} 결과 Promise
 */
const reduceAsync = curry((asyncFn: any, initAcc: any, dest: any) => {
  const initAccPromise = Promise.resolve(initAcc);
  return reduce(asyncFn, initAccPromise, dest);
});

/**
 * 비동기 forEach
 * 실행함수로 비동기 함수를 받아서 처리해준다
 * 순차실행
 *
 * @param {(a) => Promise<any>} cb 비동기 iterator
 * @param {object|any[]} collection 대상 객체 또는 배열
 * @returns {Promise<any[]>} 결과 Promise
 */
const forEachAsync = curry(async (cb: any, collection: any) => {
  const loopResults = [];
  const iterator = entries(collection);

  for (const e of iterator) {
    // @ts-expect-error: todo fix
    loopResults.push(await cb(e[1], e[0]));
  }

  return loopResults;
});

/**
 * value로 object key 조회
 *
 * @param {object} a 대상 객체
 * @param {string} v 조회 대상 key (속성명)
 * @returns {any} 속성명에 해당하는 값
 */
const key = curry((a: any, v: any) => {
  const composer = pipe(
    entries,
    find(([, val]) => equals(v, val)),
    head,
  );
  return composer(a);
});

/**
 * 대상 문자열이 json형식 문자열인지 여부 조회
 *
 * @param {string} a 조회 대상 문자열
 * @returns {boolean} json 문자열인지 여부
 */
const isJson = (a: any) => {
  const composer = pipe(attempt, isError);
  return isString(a) && !composer(() => JSON.parse(a));
};

/**
 * 원시 타입(primitive) 인지 여부 조회
 * null, undefined, Boolean, Number, String
 *
 * @param {any} a 조회 대상
 * @returns {boolean} 원시 타입(primitive) 인지 여부
 */
const isVal = (a: any) => isNil(a) || isBoolean(a) || isNumber(a) || isString(a);

/**
 * 참조 타입(reference) 인지 여부 조회
 * Array, Object, Function
 *
 * @param {any} a 조회 대상
 * @returns {boolean} 참조 타입(reference) 인지 여부
 */
const isRef = pipe(isVal, not);

/**
 * shallow freeze 보완
 * (대상 object의 refence 타입의 properties까지 object.freeze 처리)
 * @param {object} obj 대상 객체
 * @returns {object} frozen 처리된 객체
 */
const deepFreeze = (obj: Record<string, any>) => {
  const freezeRecursively = (v: Record<string, any> | any) => (isRef(v) && !Object.isFrozen(v) ? deepFreeze(v) : v);
  const composer = pipe(Object.freeze, forOwn<Record<string, any>>(freezeRecursively));
  return composer(obj);
};

/**
 * 대상 객체의 속성명을 transformFn의 결과값으로 변환
 *
 * @param {(a) => string} transformFn 변환함수
 * @param {object} dest 대상 객체
 * @returns {object} 속성명이 변환된 객체
 */
const transformObjectKey = curry((transformFn: any, dest: any) => {
  const convertRecursively = (_dest: any) => {
    const convertTo = (o: any) => {
      const composer = pipe(
        entries,
        reduce((acc, [k, v]) => {
          const _cond = cond([
            [isPlainObject, convertTo],
            [isArray, (_v: any) => _v.map((element: any) => _cond(element))],
            [T, (a: any) => a],
          ]);
          const transformedKey = transformFn(k);
          if (!has(transformedKey, acc)) {
            acc[transformedKey] = _cond(v);
            return acc;
          }
          throw new Error(`${transformedKey} already exist. duplicated property name is not supported.`);
        }, {}),
      );
      return composer(o);
    };
    return convertTo(_dest);
  };

  return isObject(dest) || isArray(dest) ? convertRecursively(dest) : dest;
});

/**
 * 대상 object의 property key문자열을 camelcase 문자열로 변환
 *
 * @param {object} a 대상 객체
 * @returns {object} 속성명이 camel case로 변환된 객체
 */
const toCamelcase = transformObjectKey(camelCase);

/**
 * 대상 object의 property key문자열을 snakecase 문자열로 변환
 *
 * @param {object} a 대상 객체
 * @returns {object} 속성명이 snake case로 변환된 객체
 */
const toSnakecase = transformObjectKey(snakeCase);
const toPascalcase = transformObjectKey(pascalCase);

/**
 * date형식 문자열 여부 조회
 * @param {string} str date형식 문자열
 * @returns {boolean} date형식 문자열 여부
 */
const isDatetimeString = (str: any) => isNaN(str) && !isNaN(Date.parse(str));
/**
 * applicative functor pattern 구현체
 * (주로 pipe함수에서 함수의 인자 순서를 변경하기 위해 사용)
 *
 * @param {any} a 대입 인자
 * @param {function} curried currying된 함수
 * @returns {any} 결과값
 */
const ap = curry((a: any, curried: any) => curried(a));

/**
 * 대상 인자가 undefined 또는 null이 아닌지 여부 조회
 *
 * @param {any} a 대상인자
 * @returns {boolean} 대상 인자가 undefined 또는 null이 아닌지 여부
 */
const isNotNil = pipe(isNil, not);

/**
 * a인자를 인자로, evaluator함수 실행,
 * true면 trueHandler에 a인자 대입
 * false면 a 반환
 *
 * @param {(a) => boolean} evaluator a를 인자로 하는 평가함수
 * @param {(a) => any} trueHandler evaluator의 결과가 true인 경우, a를 인자로 실행되는 callback
 * @param {any} a 대상 인자
 * @returns {any} evaluator가 true를 반환하는 경우, trueHandler의 결과값, false인 경우 a 반환
 */
const ifT = curry((evaluator: any, trueHandler: any, a: any) => {
  const isValidParams = every(isFunction, [evaluator, trueHandler]);

  if (isValidParams) {
    return pipe(evaluator, equals(true))(a) ? trueHandler(a) : a;
  }
  throw new Error('invalid parameter(s)');
});

/**
 * a인자를 인자로, evaluator함수 실행,
 * false면 falseHandler에 a인자 대입
 * true면 a 반환
 *
 * @param {(a) => boolean} evaluator a를 인자로 하는 평가함수
 * @param {(a) => any} falseHandler evaluator의 결과가 false인 경우, a를 인자로 실행되는 callback
 * @param {any} a 대상 인자
 * @returns {any} evaluator가 false를 반환하는 경우, falseHandler의 결과값, true경우 a 반환
 */
const ifF = curry((evaluator: any, falseHandler: any, a: any) => {
  const isValidParams = every(isFunction, [evaluator, falseHandler]);

  if (isValidParams) {
    return pipe(evaluator, equals(false))(a) ? falseHandler(a) : a;
  }
  throw new Error('invalid parameter(s)');
});

/**
 * arr인자 배열에 a인자가 포함되지 않았는지 여부 조회
 * @param {any} a 대상 인자
 * @param {any[]} arr 대상 배열
 * @returns {boolean} arr 배열에 a인자가 포함되지 않았는지 여부
 */
const notIncludes = curry((a: any, arr: any) => {
  const composer = pipe(includes, ap(arr), not);
  // todo check
  return composer(a, arr);
});

/**
 * a인자와 b인자가 다른지 여부 (deep equal) 조회
 * @param {any} a 비교 인자
 * @param {any} b 비교 인자
 * @returns {boolean} a인자와 b인자가 다른지 여부 (deep equal)
 */
const notEquals = curry((a: any, b: any) => pipe(equals(a), not)(b));

/**
 * arr인자의 idx인자의 index에 해당하는 요소 제거
 * @param {number|string} idx numeric 타입 색인값
 * @param {any[]} arr 대상 배열
 * @returns {any[]} index에 해당하는 요소 제거된 배열
 */
const removeByIndex = curry((idx: any, arr: any) => {
  if (isArray(arr)) {
    const cloned = cloneDeep(arr);
    cloned.splice(toNumber(idx), 1);

    return cloned;
  }
  return arr;
});

/**
 * 인자의 마지막 요소 제거 (immutable)
 *
 * @param {string|any[]} arr 문자열 또는 배열의 마지막 요소 제거
 * @returns 마지막 요소 제거된 인자
 */
const removeLast = (a: any) => {
  const nextA = cloneDeep(a);
  if (isArray(a)) {
    nextA.pop();
  }
  if (isString(a)) {
    return nextA.slice(0, Math.max(0, size(a) - 1));
  }
  return nextA;
};

/**
 * concat alias
 *
 * @param {any[]} array 병합대상 배열
 * @param {any|any[]} a 병합 인자
 * @returns {any[]} 병합된 배열
 */
const append = concat;

/**
 * array 인자의 (index상)앞쪽에 value인자를 추가
 *
 * @param {any[]} array 병합대상 배열
 * @param {any|any[]} value 병합 인자
 * @returns {any[]} 병합된 배열
 */
const prepend = curry((array: any, value: any) => (isArray(value) ? concat(value, array) : concat([value], array)));

/**
 * key(index)를 포함한 map
 * @param {(v, k) => any} f value, key(또는 index)를 인자로 갖는 callback
 * @param {object|any[]} a 대상 collection
 * @returns {any[]} 결과 배열
 */
const mapWithKey = curry((f: any, a: any) => map(f, a));

/**
 * key(index)를 포함한 forEach
 * @param {(v, k) => any} f value, key(또는 index)를 인자로 갖는 callback
 * @param {object|any[]} a 대상 collection
 * @returns {void} 반환값 없음
 */
const forEachWithKey = curry((f: any, a: any) => forEach(f, a));

/**
 * key(index)를 포함한 reduce
 *
 * @param {(acc, v, k) => any} f accumulator, value, key(또는 index)를 인자로 갖는 callback
 * @param {any} acc 누적기
 * @param {object|any[]} 대상 collection
 * @returns {any} 누적기
 */
const reduceWithKey = curry((f: any, acc: any, a: any) => reduce(f, acc, a));

/**
 * falsy 타입(0, -0, NaN, false, '')인지 여부 조회
 * @param {any} a 조회 대상
 * @returns {boolean} falsy 타입(0, -0, NaN, false, '')인지 여부
 */
const isFalsy = (a: any) => isNil(a) || some(equals(a), [0, -0, Number.NaN, false, '']);

/**
 * truthy 타입 인지 여부 조회
 * (falsy타입(0, -0, NaN, false, '')이 아니면 truthy 타입)
 * @param {any} a 조회 대상
 * @returns {boolean} truthy 타입 인지 여부
 */
const isTruthy = (a: any) => !isFalsy(a);

/**
 * getOr override
 *
 * getOr의 반환값이 null인 경우, 기본값 반환되게 수정한 버전
 * circular dependency 때문에 closure로 작성
 */
const getOr = curry((defaultValue: any, path: any, target: any) => {
  const val = get(path, target);
  return isNil(val) ? defaultValue : val;
});

const andThen = then;
const andCatch = otherwise;
const andFinally = _finally;
const isNotEqual = notEquals;
const isPrimitive = isVal;
const isReference = isRef;
const keyByVal = key;
const toCamelKey = toCamelcase;
const toSnakeKey = toSnakecase;
const removeByIdx = removeByIndex;
const mapWithIdx = mapWithKey;
const forEachWithIdx = forEachWithKey;
const reduceWithIdx = reduceWithKey;

export {
  andCatch,
  andFinally,
  andThen,
  ap,
  append,
  deepFreeze,
  filterAsync,
  findAsync,
  forEachAsync,
  forEachWithIdx,
  forEachWithKey,
  getOr,
  ifF,
  ifT,
  instanceOf,
  isDatetimeString,
  isFalsy,
  isJson,
  isNotEmpty,
  isNotEqual,
  isNotNil,
  isPrimitive,
  isPromise,
  isRef,
  isReference,
  isTruthy,
  isVal,
  key,
  keyByVal,
  mapAsync,
  mapWithIdx,
  mapWithKey,
  not,
  notEquals,
  notIncludes,
  pascalCase,
  prepend,
  promisify,
  reduceAsync,
  reduceWithIdx,
  reduceWithKey,
  removeByIdx,
  // array
  removeByIndex,
  removeLast,
  ternary,
  toBool,
  toCamelcase,
  toCamelKey,
  toPascalcase,
  toSnakecase,
  toSnakeKey,
  // string
  transformObjectKey,
};
