import { toUpperFirst } from './ramda-utils/toUpperFirst';

import type { Action } from './useReducer';

export type ActionCreator<P> = (payload: P, meta?: any) => Action<P>;

export const createAction = <P>(type: string): ActionCreator<P> => {
  const actionCreator = (payload: P, meta?: any) => ({ type, payload, meta });
  actionCreator.toString = () => `${type}`;
  actionCreator.TYPE = type;
  actionCreator.MATCH = (action: { type: string }) => action.type === type;

  return actionCreator;
};

export const getType = (slice: string, actionKey: string): string => `${slice}/${actionKey}`;

export const createActionFactory = <P>(
  sliceName: string,
  actionsNameArray: string[],
): { [p: string]: ActionCreator<P> } =>
  actionsNameArray.reduce(
    (acc, actionName) => ({
      ...acc,
      [`action${toUpperFirst(actionName)}`]: createAction<P>(getType(sliceName, actionName)),
    }),
    {},
  );
