import { styled } from '@mui/material/styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';

export const MessageInputStyled = styled('div')({
  width: '100%',
  maxWidth: '1024px',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  backgroundColor: 'transparent',
  borderTop: '0 none',
  borderRight: '0 none',
  borderBottom: '0 none',
  borderLeft: '0 none',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  boxSizing: 'border-box',
  padding: 0,
  overflow: 'visible',
  flexShrink: 0,
});

export const ContentEditorWrapperStyled = styled('div', {
  name: 'ContentEditorWrapperStyled',
  slot: 'Root',
})(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: `${theme.custom.inputColor}`,
  border: '1px solid',
  borderColor: `${theme.custom.inputBorderColor}`,
  padding: '5px 10px',
  borderRadius: '7px',
  flexGrow: 1,
  boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.4)',
  '& .input-buttons': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      padding: '0 5px',
    },
  },
}));

export const MessageButtonsStyled = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& button': {
    padding: 0,
  },
});

export const ContentEditableStyled = styled(TextareaAutosize, {
  name: 'ContentEditableStyled',
  slot: 'Root',
})(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  flexGrow: 1,
  backgroundColor: `${theme.custom.inputColor}`,
  borderColor: `${theme.custom.inputBorderColor}`,
  fontFamily: 'Roboto, sans-serif',
  fontSize: `${theme.typography.fontSize}px`,
  outline: 0,
  borderTop: '0 none',
  borderRight: '0 none',
  borderBottom: '0 none',
  borderLeft: '0 none',
  overflow: 'visible',
  color: `${theme.custom.inputTextColor}`,
  overflowWrap: 'anywhere',
  wordBreak: 'break-word',

  '&::placeholder': {
    paddingTop: '2px',
    color: `${theme.custom.inputPlaceholderColor}`,
  },

  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const SendBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const SendButton = styled('div')(({ theme }) => ({
  background: theme.custom.inputColor,
  border: '1px solid',
  borderColor: `${theme.custom.inputBorderColor}`,
  margin: '0 0 0 10px',
  borderRadius: 7,
  transform: 'scale(0.95)',
  '& button': {
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 7,
    // height: '38px',
    boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.4)',
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'initial',
      color: 'whiteSmoke',
    },
  },
}));

export const CancelButton = styled('div', {
  name: 'CancelButton',
  slot: 'Root',
})({
  position: 'absolute',
  right: '1px',
  padding: '0 5px',
  margin: 0,
  borderRadius: '50%',
});
