import React from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { ResizeObserver } from '@juggle/resize-observer';
import { StyledComponent } from '@emotion/styled';

import matchQueries from './matchQueries';
import unitToPx from './utils/unit-to-px';

type Props = {
  query: Record<string, Record<string, string>>;
};

type State = {
  additionalClassNames: string[];
};

class QueryContainer extends React.Component<Props, State> {
  private element: Element | Text | null | undefined;

  private observer?: ResizeObserver;

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      additionalClassNames: [],
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    this.element = ReactDOM.findDOMNode(this);
    this.observer = new ResizeObserver(this.handleResize);
    this.observer.observe(this.element as Element);
  }

  componentWillUnmount() {
    this.observer?.disconnect();
  }

  handleResize = (entry) => {
    if (entry.length !== 1) {
      return;
    }

    const { query } = this.props;
    const { width, height } = entry[0].contentRect;
    const result = matchQueries(this.parseQueryUnits(query), { width, height });
    this.setState({ additionalClassNames: result });
  };

  parseQueryUnits = (queries: Record<string, Record<string, string>>) =>
    Object.keys(queries).reduce((accumulator, key) => {
      accumulator[key] = this.parseSingleQuery(queries[key]);
      return accumulator;
    }, {});

  parseSingleQuery = (query: Record<string, string>) =>
    Object.keys(query).reduce((accumulator, innerKey) => {
      const matched = innerKey.match(/[A-Z]\w*$/) || [''];
      const prop = matched[0].toLowerCase();
      accumulator[innerKey] = unitToPx(this.element, query[innerKey], prop);
      return accumulator;
    }, {});

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, query, ...otherProps } = this.props;
    const { additionalClassNames } = this.state;

    return React.Children.map(children, (child) =>
      // @ts-ignore
      React.cloneElement(child, {
        ...otherProps,
        className: clsx(
          child && typeof child === 'object' && 'props' in child ? child.props.className : '',
          additionalClassNames,
        ),
      }),
    );
  }
}

export const withQueryContainer = (
  Component: StyledComponent<any, any, { ref?: React.Ref<any> | undefined }>,
  query: Record<string, Record<string, string>>,
) =>
  React.forwardRef((props, ref) => (
    <QueryContainer query={query}>
      <Component ref={ref} {...props} />
    </QueryContainer>
  ));
