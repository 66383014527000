import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

import { AnimationConfig } from '../../types';

const scaleClicked = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
`;

const fadeOther = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
    transform: translateY(10px);
  }
`;

const replaceToUserMsg = (config: AnimationConfig, customTheme) => {
  const { messageColor, messageBackground, messageBorderColor } = customTheme;
  const { displacementX, displacementY, fakeBtnWidth, clickedBtnWidth } = config;

  return keyframes`
    0% {
      transform: translate(0, 0);
      width: ${clickedBtnWidth}px;
    }
    100% {
      min-width: 25px;
      white-space: nowrap;
      color: ${messageColor};
      width: ${fakeBtnWidth}px;
      background: ${messageBackground};
      border: 1px solid ${messageBorderColor};
      border-radius: 10px;
      opacity: 1;
      transform: translate(${displacementX}px, ${displacementY}px);
      font-size: 14px;
      padding: 6px 10px;
      word-break: break-word;
    }
  `;
};

export const OptionsContainer = styled('div')<{ animationconfig: AnimationConfig }>((props) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginRight: '-5px',
  maxWidth: '500px',
  position: 'relative',
  transition: '.5s',
  '& .scale': {
    animation: `
      .5s ease-in-out ${scaleClicked},
      ${props.animationconfig.animationSpeed} ease-in-out ${replaceToUserMsg(
      props.animationconfig,
      props.theme.custom,
    )} 1s forwards`,
  },
  '& .fade': {
    animation: `.5s ${fadeOther} ease-in-out .5s forwards`,
  },
}));

export const AvatarWrapper = styled('div')((props) => ({
  minWidth: '37px',
  maxWidth: '37px',
  height: '36px',
  marginLeft: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: props.theme.custom.messageColorBot,
  background: props.theme.custom.messageIconBackgroundBot,
  borderRadius: '50%',
  boxShadow: '3px 3px 5px rgba(0,0,0,0.4)',
  '& img': {
    width: '28px',
  },
}));
