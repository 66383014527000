import { useMemo } from 'react';

import { debounce, throttleRaf } from '../utils';

export const useDebounce = (fn: () => void, debounceMs: number): (() => void) => {
  if (typeof fn !== 'function') {
    throw new TypeError(`Invalid type. Expected function but received ${typeof fn}`);
  }

  return useMemo(() => debounce(fn, debounceMs), [fn, debounceMs]);
};

export const useDebounceRaf = (fn: () => void): (() => void) => {
  if (typeof fn !== 'function') {
    throw new TypeError(`Invalid type. Expected function but received ${typeof fn}`);
  }

  return useMemo(() => throttleRaf(fn), [fn]);
};
