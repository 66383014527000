/*
  Lite version of https://github.com/heygrady/Units
 */

const dummyFn = (element, value) => (value.match(/px$/) ? parseFloat(value) : undefined);

// eslint-disable-next-line sonarjs/cognitive-complexity
const unitToPx = (() => {
  const runit = /^(-?[\d+.-]+)([a-z]+|%)$/i;

  if (typeof document === 'undefined') {
    return dummyFn;
  }

  let testElem: HTMLElement | undefined = document.createElement('test');
  const docElement = document.documentElement;

  const conversions = {
    in: null,
    mm: 1 / 25.4,
    cm: 1 / 2.54,
    pt: 1 / 72,
    pc: 1 / 6,
  };
  let convert;
  // eslint-disable-next-line prefer-const
  convert = Object.keys(conversions).reduce((accumulator, unit) => {
    if (conversions[unit] === null) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      accumulator[unit] = toPx(testElem, `1${unit}`);
      return accumulator;
    }
    // @ts-ignore
    accumulator[unit] = conversions[unit] * accumulator.in;
    return accumulator;
  }, {});

  function toPx(elem, value, prop = 'width') {
    const unit = (value.match(runit) || [])[2];

    if (convert && unit in convert) {
      return parseFloat(value) * convert[unit];
    }

    if (unit === 'px') {
      return parseFloat(value);
    }

    if (unit === 'rem' || unit === 'em') {
      if (unit === 'rem') {
        // eslint-disable-next-line no-param-reassign
        elem = docElement;
      }

      const { fontSize } = window.getComputedStyle(elem);

      return parseFloat(value) * parseFloat(fontSize);
    }

    const inlineValue = elem.style[prop];
    // eslint-disable-next-line no-param-reassign
    elem.style[prop] = value;
    const pxSize = !elem.style[prop] ? 0 : parseFloat(window.getComputedStyle(elem)[prop]);
    // eslint-disable-next-line no-param-reassign
    elem.style[prop] = inlineValue;

    return pxSize;
  }

  docElement.appendChild(testElem);
  docElement.removeChild(testElem);
  testElem = undefined;

  return toPx;
})();

export default unitToPx;
