import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Box from '@mui/material/Box';

import { MEDIA_QUERY } from '../../constants';
import { Alignment } from '../../types';

const iconAnimationDuration = '0.2s';
const initIcon = keyframes`
  0% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(1);
  }
`;

const initMessage = (horizontalAlignmentType) => keyframes`
  0% {
    transform: translateY(3px) rotate(${horizontalAlignmentType === 'left' ? '10deg' : '-10deg'});
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
`;

export const MainChatIconContainer = styled(Box)<Alignment>`
  display: flex;
  align-items: flex-start;
  position: fixed;
  z-index: 10000;
  ${(props) => props.verticalAlignmentType}: ${(props) => props.verticalAlignment}px;
  ${(props) => props.horizontalAlignmentType}: ${(props) => props.horizontalAlignment}px;
  ${(props) => props.horizontalAlignmentType === 'left' && 'flex-direction: row-reverse'};
`;

const ICON_WRAPPER_SIZE = {
  DESKTOP: '65px',
  MOBILE: '55px',
};

export const IconWrapper = styled(Box)<{ iconBackground: string }>`
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transform-origin: center;
  animation-name: ${initIcon};
  animation-duration: ${iconAnimationDuration};
  animation-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
  width: ${ICON_WRAPPER_SIZE.DESKTOP};
  height: ${ICON_WRAPPER_SIZE.DESKTOP};
  background-color: ${(props) => props.iconBackground};

  @media (max-width: ${MEDIA_QUERY.mobile}px) {
    width: ${ICON_WRAPPER_SIZE.MOBILE};
    height: ${ICON_WRAPPER_SIZE.MOBILE};
  }
`;

export const Icon = styled.img`
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  margin: 0;
  object-fit: contain;
`;

type MessageTypes = {
  fontFamily?: string;
  iconMessageBackground: string;
  iconMessageColor: string;
  horizontalAlignmentType: string;
};

export const Message = styled(Box)<MessageTypes>`
  opacity: 0;
  font-family: ${(props) => props.fontFamily ?? 'Roboto, sans-serif'};
  font-size: 12px;
  border-radius: 10px;
  text-align: left;
  user-select: none;
  padding: 6px 10px;
  word-break: break-word;
  white-space: nowrap;
  animation-duration: ${iconAnimationDuration};
  animation-delay: ${iconAnimationDuration};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-name: ${(props) => initMessage(props.horizontalAlignmentType)};
  margin-${(props) => props.horizontalAlignmentType}: 5px;
  transform-origin: bottom ${(props) => props.horizontalAlignmentType};
  background: ${(props) => props.iconMessageBackground};
  color: ${(props) => props.iconMessageColor};

  /*@media (max-width: ${MEDIA_QUERY.mobile}px) {
    display: none;
  }*/
`;
