import { styled } from '@mui/material/styles';

export const HeaderWrapper = styled('div', {
  name: 'HeaderWrapper',
  slot: 'Root',
})(({ theme }) => ({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '18px',
  lineHeight: 0,
  userSelect: 'none',
  minHeight: `${theme.custom.headerHeight}`,
  background: `${theme.custom.headerBackground}`,
  width: '100%',
  color: `${theme.custom.headerColor}`,
  position: 'relative',
  border: `1px solid ${theme.custom.headerBorderColor}`,
  borderTopLeftRadius: `${theme.custom.windowBorderRadius}`,
  borderTopRightRadius: `${theme.custom.windowBorderRadius}`,
  boxSizing: 'border-box',
}));

export const Draggable = styled('div')((props: { cursorhover: string; cursoractive: string }) => ({
  position: 'absolute',
  zIndex: 10001,
  width: '100%',
  height: '100%',
  '&:hover': {
    cursor: `${props.cursorhover}`,
  },
  '&:active': {
    cursor: `${props.cursoractive}`,
  },
}));

export const SubHeaderWrapper = styled('div')({
  textAlign: 'center',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const SubNav = styled('div')({});

export const SubNavIcon = styled('div')({
  display: 'inline-block',
  position: 'absolute',
  top: '10px',
  zIndex: 10100,
  cursor: 'pointer',
});

export const SubNavFullscreenIcon = styled(SubNavIcon)({ right: '32px' });

export const SubNavExitIcon = styled(SubNavIcon)({ right: '7px' });
