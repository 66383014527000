/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react';
import useTheme from '@mui/styles/useTheme';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';

import { Theme } from '@mui/material';
import {
  ContentEditorWrapperStyled,
  MessageInputStyled,
  ContentEditableStyled,
  CancelButton,
  SendBox,
  SendButton,
  MessageButtonsStyled,
} from './styles';
import { noop, useControllableState } from './helpers';

type MessageInputInnerProps = {
  /** Value. */
  value?: string;
  /** Placeholder. */
  placeholder: string;
  /** A input can show it is currently unable to be interacted with. */
  disabled?: boolean;
  /** Prevent that the input message is sent on a return press */
  sendOnReturnDisabled?: boolean;
  /** Send button can be disabled.<br>
   * It's state is tracked by component, but it can be forced */
  sendDisabled?: boolean;
  /** Set focus after mount. */
  autoFocus?: boolean;
  /**
   * onChange handler<br>
   * @param {String} innerHtml
   * @param {String} textContent
   * @param {String} innerText
   * @param {NodeList} nodes
   */
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>, content: string) => void;
  /**
   * onSend handler<br>
   * @param {String} innerHtml
   * @param {String} textContent
   * @param {String} innerText
   * @param {NodeList} nodes
   */
  onSend: (stateValue: string, content: string) => void;

  /** Additional classes. */
  className?: string;

  /** Show send button */
  sendButton?: boolean;
};

function MessageInputInner(props, ref) {
  const {
    value = undefined,
    onSend = noop,
    onChange = noop,
    autoFocus = false,
    placeholder = '',
    disabled = false,
    sendDisabled,
    sendOnReturnDisabled = false,
    sendButton = true,
    ...rest
  } = props;

  const theme = useTheme<Theme>();
  const msgRef = useRef<HTMLTextAreaElement>(null);
  const [stateValue, setStateValue] = useControllableState(value, '');
  const [stateSendDisabled, setStateSendDisabled] = useControllableState(sendDisabled, true);

  // Public API
  const focus = () => {
    if (msgRef.current) {
      msgRef.current.focus();
    }
  };

  // Return object with public Api
  useImperativeHandle(ref, () => ({ focus }));

  // Set focus
  useEffect(() => {
    if (autoFocus === true) {
      focus();
    }
  }, [autoFocus]);

  // Update scroll
  useEffect(() => {
    const textarea = msgRef.current;

    if (textarea) {
      textarea.scrollTop = textarea.scrollHeight - textarea.clientHeight;
    }
  });

  const getContent = (): string => {
    // Direct reference to ContentEditable element
    const contentEditableRef = msgRef.current;

    return contentEditableRef?.value as string;
  };

  const handleChange = useCallback(
    (event) => {
      setStateValue(event.target.value);
      if (typeof sendDisabled === 'undefined') {
        setStateSendDisabled(event.target.value.length === 0);
      }

      const content = getContent();

      onChange(event, content);
    },
    [onChange, sendDisabled, setStateSendDisabled, setStateValue],
  );

  const send = () => {
    if (stateValue.trim() === '') {
      setStateValue('');
    } else if (stateValue.length > 0) {
      // Clear input only when it's uncontrolled mode
      if (value === undefined) {
        setStateValue('');
      }

      // Disable send button only when it's uncontrolled mode
      if (typeof sendDisabled === 'undefined') {
        setStateSendDisabled(true);
      }

      const content = getContent();

      onSend(stateValue, content);
    }
  };

  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter' && evt.shiftKey === false && !sendOnReturnDisabled) {
      evt.preventDefault();
      send();
    }
  };

  return (
    <MessageInputStyled ref={ref} {...rest}>
      <ContentEditorWrapperStyled>
        <ContentEditableStyled
          ref={msgRef}
          disabled={disabled}
          placeholder={placeholder}
          value={stateValue}
          style={{ resize: 'none', overflow: 'auto' }}
          maxRows={5}
          minRows={1}
          onInput={handleChange}
          onKeyPress={handleKeyPress}
        />
        <MessageButtonsStyled>
          {stateValue && (
            <CancelButton>
              <IconButton
                onClick={() => {
                  handleChange({ target: { value: '' } });
                }}
              >
                <CancelIcon sx={{ color: theme.custom.inputCancelButtonColor }} />
              </IconButton>
            </CancelButton>
          )}
        </MessageButtonsStyled>
      </ContentEditorWrapperStyled>

      {sendButton && (
        <SendBox>
          <SendButton>
            <IconButton disabled={disabled || stateSendDisabled === true} onClick={send}>
              <SendIcon
                sx={{ color: disabled || stateSendDisabled === true ? theme.custom.sendButtonIconDisabledColor : theme.custom.sendButtonIconColor }}
              />
            </IconButton>
          </SendButton>
        </SendBox>
      )}
    </MessageInputStyled>
  );
}

export const MessageInput = forwardRef<HTMLDivElement, MessageInputInnerProps>(MessageInputInner);
