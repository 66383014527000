import React, { useCallback, useRef } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';

import Add from '@mui/icons-material/Add';
import RestartAlt from '@mui/icons-material/RestartAlt';
import Remove from '@mui/icons-material/Remove';

import { useKeypress } from '../../../hooks/useKeypress';
import { ClosedButton, Img, ZoomButton, ZoomButtonsContainer } from './styled';

const minScale = 1;
const maxScale = 3;
const defaultPosition = { x: 0, y: 0, scale: minScale, animated: true };
const zoomCoefficient = 0.2;

type ImageViewerProps = {
  src: string;
  handleClose: () => void;
};

export function ImageViewer(props: ImageViewerProps): JSX.Element {
  const { src, handleClose } = props;
  useKeypress('Escape', handleClose);

  const [position, setPosition] = React.useState(defaultPosition);
  const nodeRef = useRef<HTMLImageElement>(null);
  const quickPinchZoomRef = useRef<QuickPinchZoom>(null);

  const onUpdate = useCallback(
    ({ x, y, scale: pinchScale }): void => {
      if (nodeRef.current) {
        const value = make3dTransformValue({ x, y, scale: pinchScale });
        setPosition({ ...position, x, y, scale: pinchScale });
        nodeRef.current.style.setProperty('transform', value);
      }
    },
    [position],
  );

  const onZoomIn = (): void => {
    const { scale } = position;

    if (scale < maxScale) {
      setPosition({ ...position, scale: scale + zoomCoefficient });
      quickPinchZoomRef.current?.scaleTo({ ...position, scale: scale + zoomCoefficient });
    }
  };

  const onZoomOut = (): void => {
    const { scale } = position;

    if (scale > minScale) {
      setPosition({ ...position, scale: scale - zoomCoefficient });
      quickPinchZoomRef.current?.scaleTo({ ...position, scale: scale - zoomCoefficient });
    }
  };

  const onReset = useCallback((): void => {
    quickPinchZoomRef.current?.scaleTo(defaultPosition);
  }, []);

  return (
    <>
      <ClosedButton onClick={handleClose} title="exit" />
      <ZoomButtonsContainer>
        <ZoomButton onClick={onZoomOut}>
          <Remove />
        </ZoomButton>
        <ZoomButton onClick={onZoomIn}>
          <Add />
        </ZoomButton>
        <ZoomButton onClick={onReset}>
          <RestartAlt />
        </ZoomButton>
      </ZoomButtonsContainer>
      <QuickPinchZoom
        ref={quickPinchZoomRef}
        enabled={position.scale > minScale}
        inertia={false}
        containerProps={{
          style: {
            touchAction: 'auto',
            maxWidth: '90vw',
            maxHeight: '90vh',
            overflow: 'hidden',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        wheelScaleFactor={100}
        shouldInterceptWheel={() => false}
        maxZoom={maxScale}
        minZoom={minScale}
        zoomOutFactor={1}
        onUpdate={onUpdate}
      >
        <Img ref={nodeRef} src={src} alt="ResponseBotImage" />
      </QuickPinchZoom>
      {/* <IconButton */}
      {/*  className={classes.sendButton} */}
      {/*  disabled={disabled === true || stateSendDisabled === true} */}
      {/*  onClick={send} */}
      {/* > */}
      {/*  <SendIcon /> */}
      {/* </IconButton> */}
    </>
  );
}
