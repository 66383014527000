import React, { ReactElement } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from '@react-spring/web';

import { PortalContext } from '../../ChatWindow/PortalContext';
import { ImageViewer } from './ImageViewer';

type FadeProps = {
  in: boolean;
  children: ReactElement;
};

const Fade = React.forwardRef((props: FadeProps, ref: any) => {
  // @ts-ignore
  const { in: open, children, onEnter, onExited, ...other } = props;

  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export function Image({ src, alt = 'ResponseBotImage', ...rest }) {
  const [open, setOpen] = React.useState(false);
  const portalContext = React.useContext(PortalContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <img src={src} alt={alt} onClick={handleOpen} {...rest} />
      <Modal
        disableAutoFocus
        disableEnforceFocus
        closeAfterTransition
        open={open}
        onClose={handleClose}
        container={portalContext.current}
        style={{
          zIndex: 2147483647,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
          timeout: 500,
        }}
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <ImageViewer src={src} handleClose={handleClose} />
        </Fade>
      </Modal>
    </>
  );
}
