import React from 'react';
import { HubConnection } from '@microsoft/signalr';

import { Locale, State } from './types';
import { Store } from './store/Store';
import { ThemeProvider } from './theme/ThemeProvider';
import { ShadowDomContainer } from './components/ShadowDomContainer';
import { ChatWindow } from './components/ChatWindow/ChatWindow';
import { MemoMainChatIcon } from './components';
// import { toggleFullScreen } from './utils/tools';

interface AppProps {
  // eslint-disable-next-line react/require-default-props
  localizedTexts?: Locale;
  channelKey: string;
  // eslint-disable-next-line react/require-default-props
  hubConnection?: HubConnection;
  clientData: string;
}

interface WidgetProps extends Omit<AppProps, 'localizedTexts'> {
  initialState: State;
}

export function Widget(props: WidgetProps) {
  const { channelKey, hubConnection, clientData, initialState } = props;

  const portalRef = React.useRef<HTMLDivElement | null>(null);

  const palette = initialState.paletteTypes[initialState.paletteType];

  return (
    <ShadowDomContainer>
      <ThemeProvider
        paletteMode={initialState.paletteType}
        palette={palette}
        typography={initialState.typography}
        images={initialState.staticImages}
        elementSizes={initialState.elementSizes}
      >
        <Store initialState={initialState}>
          {(state) => (
            <>
              {!state.hideIcon && !state.isEnabled && (
                <MemoMainChatIcon iconMessageText={state.localizedTexts?.iconMessageText} />
              )}
              <ChatWindow
                isEnabled={state.isEnabled}
                withThoughts={state.withThoughts}
                userID={state.userID}
                headerText={state.localizedTexts?.headerText}
                clientData={clientData}
                channelKey={channelKey}
                hubConnection={hubConnection}
                portalRef={portalRef}
                messagesHistory={state.messagesHistory}
                isCopyrightVisible={state.isCopyrightVisible}
                appendTo={state.appendTo}
                autoFocus={state.autoFocus}
              />
              <div>
                <div ref={portalRef} />
              </div>
            </>
          )}
        </Store>
      </ThemeProvider>
    </ShadowDomContainer>
  );
}
