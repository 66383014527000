/* eslint-disable class-methods-use-this,@typescript-eslint/no-unused-vars */
export function trim(str: string) {
  // @ts-ignore
  return String.prototype.trim ? str.trim() : str.replace(/(^\s*|\s*$)/g, '');
}

export function removePrefix(str: string, prefix: string) {
  return str.indexOf(`${prefix}-`) === 0 ? str.slice(prefix.length) : str;
}

export function isString(value: any) {
  return value != null && (typeof value === 'string' || Object.prototype.toString.call(value) === '[object String]');
}

export function isFunction(value: any) {
  const tag = Object.prototype.toString.call(value);
  const asyncTag = '[object AsyncFunction]';
  const funcTag = '[object Function]';
  const genTag = '[object GeneratorFunction]';
  return tag === asyncTag || tag === funcTag || tag === genTag;
}

export function beginsWith(str: string, prefix: string) {
  return str.substr(0, prefix.length) === prefix;
}

export function iterateStorage(instance: any, callback: (key: string, driverElement: any) => void) {
  const driver = instance._driver;

  Object.keys(driver).forEach((key) => {
    if (beginsWith(key, instance._keyPrefix)) {
      callback(key, driver[key]);
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (...args: any[]) => undefined;

class DefaultStorage implements Storage {
  // eslint-disable-next-line no-restricted-globals
  [name: string]: any;

  readonly length: number = 0;

  clear(): void {
    /**/
  }

  getItem(_key: string): string | null {
    return null;
  }

  key(_index: number): string | null {
    return null;
  }

  removeItem(_key: string): void {
    /**/
  }

  setItem(_key: string, _value: string): void {
    /**/
  }
}

export function hasStorage(storageType: 'localStorage' | 'sessionStorage') {
  try {
    const storage = window[storageType];
    const testKey = '__web-storage__test';

    storage.setItem(testKey, 'test');
    storage.getItem(testKey);
    storage.removeItem(testKey);
  } catch (e) {
    return false;
  }

  return true;
}

export function getStorage(storageType: 'localStorage' | 'sessionStorage'): Storage {
  if (hasStorage(storageType)) {
    return window[storageType];
  }

  console.warn('WidgetCacheStorage failed to create sync storage; falling back to noop storage.');

  return new DefaultStorage();
}
