import React, { memo } from 'react';
import fastDeepEqual from 'fast-deep-equal';
import { useSelector, createSelector } from '@wpa/redux-utils';

import { DateText, DateWrapper } from './styled';

type MessagesSendDayProps = {
  date: Date;
};

export const MessagesSendDay = memo(({ date }: MessagesSendDayProps) => {
  const lang = useSelector(createSelector(['app', 'lang']), fastDeepEqual);

  return (
    <DateWrapper>
      {/* <div style={{ flexBasis: '90%', height: '1px', margin: '0 10px', backgroundColor: 'black', opacity: 0.3 }} /> */}
      <DateText variant="caption">
        {date.toLocaleDateString(lang, { day: '2-digit', weekday: 'long', month: 'long' })}
      </DateText>
      {/* <div style={{ flexBasis: '90%', height: '1px', margin: '0 10px', backgroundColor: 'black', opacity: 0.3 }} /> */}
    </DateWrapper>
  );
});
