import React, { useEffect } from 'react';
import useTheme from '@mui/styles/useTheme';
import { Theme } from '@mui/material';

import { SendMessageFunc, SetAnimationConfig } from '../../../types';
import { BaseButton } from '../../BaseButton';

export type Positions = {
  buttonPositionX: number;
  buttonPositionY: number;
  leftContainerIndent: number;
};

type OptionProps = {
  action: string;
  sendMessage: SendMessageFunc;
  actionIndex: number;
  id: string;
  replyActionIndex?: number;
  disabled: boolean;
  className: string;
  setAnimationConfig: SetAnimationConfig;
  setClickedButton: (options: { idx: number; text: string }) => void;
  optionsContainerRef: any;
  animationEnabled: boolean;
  sendMessageDelay: number;
};

export const Option = React.memo<OptionProps>((props) => {
  const {
    action,
    sendMessage,
    actionIndex,
    id,
    replyActionIndex,
    disabled,
    setClickedButton,
    className,
    setAnimationConfig,
    optionsContainerRef,
    animationEnabled,
    sendMessageDelay,
  } = props;

  const { custom } = useTheme<Theme>();
  const [isClicked, setIsClicked] = React.useState(disabled);

  const fieldBackground =
    actionIndex === replyActionIndex ? custom.clickedOptionColor || custom.fieldBorderColor : custom.fieldBackground;

  React.useEffect(() => {
    setIsClicked(disabled);
  }, [disabled]);

  const buttonRef = React.useRef<HTMLDivElement | null>(null);

  const sendMsg = React.useCallback(() => {
    sendMessage({
      value: action,
      type: 'button',
      botMessageReplyId: id,
      replyActionIndex: actionIndex,
    });
  }, [action, actionIndex, id, sendMessage]);

  const animatedSendOptionHandler = (e) => {
    setClickedButton({
      idx: actionIndex,
      text: e.target.textContent,
    });

    setAnimationConfig((prev) => ({
      ...prev,
      leftIndent: optionsContainerRef.current.offsetLeft,
      buttonPositionX: buttonRef.current?.offsetLeft || 0,
      buttonPositionY: buttonRef.current?.offsetTop || 0,
      clickedBtnWidth: e.target.offsetWidth,
    }));

    setTimeout(sendMsg, sendMessageDelay);
  };

  const sendOptionHandler = React.useCallback(() => {
    setIsClicked(true);
    sendMsg();
  }, [sendMsg]);

  const sendOption = animationEnabled ? animatedSendOptionHandler : sendOptionHandler;

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.style.width = `${buttonRef.current.offsetWidth - 5}px`;
    }
  }, []);

  return (
    <BaseButton
      ref={buttonRef}
      disabled={isClicked}
      onClick={sendOption}
      fieldBackground={fieldBackground}
      className={className}
    >
      {action}
    </BaseButton>
  );
});
