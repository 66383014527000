import type { Draft } from 'immer';

import type { Action, Reducer } from './useReducer';
import type { ActionCreator } from './createAction';
import { createAction, createActionFactory, getType } from './createAction';
import { toUpperFirst } from './ramda-utils/toUpperFirst';
import { createReducer } from './createReducer';

export type ReducerCase<S, A> = (draft: Draft<S>, actions: A) => void;
export type ReducerCases<S, A, T extends Record<string, ReducerCase<S, A>>> = {
  [P in keyof T]: T[P];
};
export type ReducerCasesMap<S, A> = Record<string, ReducerCase<S, A>>;
export type Params<R> = {
  name: string;
  reducerCases: R;
  withActionPrefix?: boolean;
  actionPrefix?: string;
  actionsWithoutReducer?: Array<string>;
};
export type Slice<S> = {
  name: string;
  reducer: Reducer<S, Action<any>>;
  actions: { [p: string]: ActionCreator<any> };
};

export function createSlice<S, R>(params: Params<R>): Slice<S> {
  const { name, reducerCases, withActionPrefix = true, actionPrefix = 'action', actionsWithoutReducer = [] } = params;

  if (!name) {
    throw new Error('`name` is a required option for createSlice');
  }

  const mappedReducerCases: ReducerCasesMap<S, Action<any>> = {};
  const actionCreators: Record<string, ActionCreator<any>> = {};

  Object.keys(reducerCases).forEach((reducerCaseName) => {
    const caseReducer = reducerCases[reducerCaseName];
    const type = getType(name, reducerCaseName);

    mappedReducerCases[type] = caseReducer;
    const actionName = `${withActionPrefix ? actionPrefix : ''}${
      withActionPrefix ? toUpperFirst(reducerCaseName) : reducerCaseName
    }`;
    actionCreators[actionName] = createAction<any>(type);
  });

  const reducer = createReducer<ReducerCasesMap<S, Action<any>>, Action<any>>(mappedReducerCases);

  const otherActions = createActionFactory(name, actionsWithoutReducer);

  return {
    name,
    // @ts-ignore
    reducer,
    actions: { ...actionCreators, ...otherActions },
  };
}
