/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Scrollbar } from 'react-scrollbars-custom';
import React from 'react';
import { applySpec } from 'ramda';
import fastDeepEqual from 'fast-deep-equal';
import { useSelector, createSelector } from '@wpa/redux-utils';

import findLastIndex from 'lodash/findLastIndex';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import { History, Locale, MarkerEvent, Message, SendMessageFunc, State } from '../../types';
// import { SENDER_TYPE } from '../../constants';
import { Typing } from '../Typing/Typing';
import { MessageComponent } from '../Message/Message';
import { BodyBox } from './styled';
import { /* isNeedScrollToBotMessage, */ scrollDown } from './BodyHelper';
import { usePostMessage } from '../../hooks/usePostMessage';
import { patchHistoryMessage, setSelectedMessage } from '../../store/rootSlice';
import { POST_MESSAGE_EVENT_TYPE } from '../../constants';

type ScrollRef =
  | string
  | (string & ((instance: HTMLDivElement | null) => void))
  | (string & React.RefObject<Scrollbar>);

type BodySelected = {
  messagesHistory: History;
  selectedMessage: Message;
  isTyping: boolean;
  isFullScreen: boolean;
  localizedTexts: Locale;
  withThoughts: boolean;
};

const bodyContentSelector = applySpec<BodySelected>({
  withThoughts: createSelector(['app', 'withThoughts']),
  selectedMessage: createSelector(['app', 'selectedMessage']),
  messagesHistory: createSelector(['app', 'messagesHistory']),
  isTyping: createSelector(['app', 'isTyping']),
  isFullScreen: createSelector(['app', 'isFullScreen']),
  localizedTexts: createSelector(['app', 'localizedTexts']),
});

export function ThoughtsBody() {
  const { messagesHistory = [], selectedMessage } = useSelector<State, BodySelected>(
    bodyContentSelector,
    fastDeepEqual,
  );

  const lastUserMessageIndex = findLastIndex(messagesHistory, { type: 'userMessage' });
  const selectedMessageIndex = findIndex(messagesHistory, { data: { id: selectedMessage?.data?.id } });

  const nextMessageIndex = selectedMessageIndex ?? lastUserMessageIndex;

  const nextBotMessage = messagesHistory[nextMessageIndex + 1];
  const incomingEventId = nextBotMessage?.data?.incomingEventId;
  const thoughts = filter(messagesHistory, { data: { isThought: true, incomingEventId } });

  return (
    <Scrollbar>
      <BodyBox>
        {thoughts.map((message, idx, arr) => {
          const isLastMessage = idx === arr.length - 1;
          return (
            <MessageComponent
              key={message.data.id}
              // @ts-ignore
              message={message}
              prevMessage={arr[idx - 1]}
              botName="Bot Thoughts"
              isFirsMessage={idx === 0}
              isLastMessage={isLastMessage}
              isFullScreen={false}
              sendMessage={() => console.log('send')}
              sendToParent={() => console.log('send')}
            />
          );
        })}
      </BodyBox>
    </Scrollbar>
  );
}

type BodyContentProps = {
  iORef: (node?: Element | null | undefined) => void;
  isOnline: boolean;
  scrollRef: React.MutableRefObject<Scrollbar | null>;
  sendMessage: SendMessageFunc;
};

function BaseBodyContent(props: BodyContentProps) {
  const { iORef, isOnline, scrollRef, sendMessage } = props;

  const {
    messagesHistory = [],
    isTyping,
    isFullScreen,
    localizedTexts,
    withThoughts,
  } = useSelector<State, BodySelected>(bodyContentSelector, fastDeepEqual);

  const { botName } = localizedTexts;

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        // scrollDown(scrollRef);
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }, 50);
  }, [scrollRef]);

  React.useLayoutEffect(() => {
    if (messagesHistory.length) {
      const lastMessage = messagesHistory[messagesHistory.length - 1];
      if (lastMessage.data /* .inputText */ /* && lastMessage.type === SENDER_TYPE.user */) {
        scrollDown(scrollRef);
      }
    }
  }, [messagesHistory, scrollRef]);

  const { sendToParent } = usePostMessage<MarkerEvent, MarkerEvent>(POST_MESSAGE_EVENT_TYPE, (_callback, payload) => {
    patchHistoryMessage(payload);
  });

  const filteredMessages = filter(messagesHistory, ({ data }) => data.isThought !== true);

  const handleUserMessageClick = (message: Message) => {
    if (withThoughts && message.isMine) setSelectedMessage(message);
  };

  return (
    <Scrollbar ref={scrollRef as ScrollRef}>
      <BodyBox>
        {filteredMessages?.map((message, idx, arr) => {
          const isLastMessage = idx === arr.length - 1;
          return (
            <MessageComponent
              key={message.data.id}
              ref={isLastMessage ? iORef : null} // isLastMessage
              // @ts-ignore
              message={message}
              prevMessage={arr[idx - 1]}
              botName={botName}
              isFirsMessage={idx === 0}
              isLastMessage={isLastMessage}
              isFullScreen={isFullScreen}
              sendMessage={sendMessage}
              sendToParent={sendToParent}
              onClick={() => handleUserMessageClick(message)}
            />
          );
        })}
      </BodyBox>
      {isTyping && isOnline && (
        <Typing
        // content={`${botName} is typing`}
        />
      )}
    </Scrollbar>
  );
}

export const BodyContent = React.memo(BaseBodyContent);
