import React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import { BaseButton } from '../../BaseButton';
import { Buttons, Checkbox, CheckboxLabel, CheckboxWrap, CountriesWrap, CheckboxesContainer } from './styled';

const saveClickedButtonsOption = (clickedOptions) => {
  sessionStorage.setItem('sbu-clicked-buttons-options', JSON.stringify(clickedOptions));
};
const getClickedButtonsOptions = () =>
  JSON.parse(sessionStorage.getItem('sbu-clicked-buttons-options') as string) || {};

export function Countries({ countries, sendMessage, message }) {
  const { id } = message;

  const [isSentEarlier, selectedValue] = React.useMemo(() => {
    const clickedOptions = getClickedButtonsOptions();
    const $isSentEarlier = Boolean(clickedOptions[id]);
    const $selectedValue = $isSentEarlier ? clickedOptions[id].value : null;
    return [$isSentEarlier, $selectedValue];
  }, [id]);

  const countriesRef = React.useRef(null);
  const [country, setCountry] = React.useState(selectedValue);
  const [inputText, setInputText] = React.useState('');
  const [showAllList, setShowAllList] = React.useState(false);
  const [isSent, setIsSent] = React.useState(isSentEarlier);

  const saveChoose = React.useCallback(() => {
    const clickedOptions = getClickedButtonsOptions();
    clickedOptions[id] = {
      value: country,
    };
    saveClickedButtonsOption({ ...clickedOptions });
  }, [id, country]);

  const setInputValue = (e) => {
    setCountry(e.currentTarget.value);
  };

  const onClickChooseBtn = () => {
    sendMessage({ value: country, type: 'button' });
    saveChoose();
    setIsSent(true);
  };

  const takeMessText = (event) => {
    event.preventDefault();

    setInputText(event.target.value);

    if (event.target.value.length > 0) {
      setShowAllList(true);
    } else {
      setShowAllList(false);
    }
  };

  const infoSearch = [...countries];

  const itemSearch = (items, terms) => {
    if (terms.length === 0) {
      return items;
    }
    return items.filter((item) => item.toLowerCase().indexOf(terms) > -1);
  };

  const visibleItems = itemSearch(infoSearch, inputText.toLowerCase());

  const showAllListFunc = () => {
    setShowAllList(!showAllList);
  };

  return (
    <CountriesWrap ref={countriesRef} buttonDisableAfterClick={isSent}>
      <Paper elevation={3} sx={{ p: '7px' }}>
        <TextField
          fullWidth
          size="small"
          label="Find items"
          variant="outlined"
          value={inputText}
          onChange={takeMessText}
        />
      </Paper>
      <Buttons>
        <BaseButton disabled={isSent} onClick={showAllListFunc}>
          {showAllList ? 'Hide' : 'Show More'}
        </BaseButton>
        <BaseButton onClick={onClickChooseBtn} disabled={!country || isSent}>
          Choose
        </BaseButton>
      </Buttons>
      {/* @ts-ignore */}
      <CheckboxesContainer expand={showAllList}>
        {visibleItems.map((item, i) => (
          <CheckboxWrap key={`CheckboxWrap_${i + 1}`}>
            <CheckboxLabel>
              <Checkbox
                type="radio"
                name="country"
                value={item}
                onClick={setInputValue}
                defaultChecked={item === country}
                disabled={isSent}
              />
              {item}
            </CheckboxLabel>
          </CheckboxWrap>
        ))}
      </CheckboxesContainer>
    </CountriesWrap>
  );
}
