export const BOT_CONTAINER_ID = 'MetaDialogChatBotApp';

export const SENDER_TYPE = { bot: 'botMessage', user: 'userMessage' };

export const MEDIA_QUERY = { mobile: 576 };

// cache storage base keys
export const USER_ID_KEY = 'userID';
export const IS_ENABLED_KEY = 'isEnabled';
export const IS_FULLSCREEN_KEY = 'isFullScreen';
export const CURRENT_SIZE_KEY = 'currentSize';
export const CURRENT_POSITION_KEY = 'currentPosition';
export const CHAT_HISTORY_KEY = 'chat-history';
export const CLICKED_OPTIONS_KEY = 'clicked-options';

export const POST_MESSAGE_EVENT_TYPE = 'widget';
