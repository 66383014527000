import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { v4 as uuidv4 } from 'uuid';

import { SENDER_TYPE } from '../../constants';
import { UserMessage, SendMessageFunc } from '../../types';
import { setIsTyping, setMessagesHistory, setSelectedMessage } from '../../store/rootSlice';

const SB = 'SmartBot => ';

export async function start(connection: HubConnection | undefined, afterConnectionCallback: () => void): Promise<void> {
  try {
    console.info(SB, 'Connecting...');

    if (connection && connection.state !== HubConnectionState.Connected) {
      await connection.start().then(() => {
        console.info('Connected.');
        afterConnectionCallback();
      });
    }
  } catch (err) {
    console.error(err);

    setTimeout(() => {
      start(connection, afterConnectionCallback);
    }, 5000);
  }
}

export function sendMessageToWS(connection: HubConnection | undefined, message: UserMessage): void {
  /* if (
    connection &&
    connection.state !== HubConnectionState.Connecting &&
    connection.state !== HubConnectionState.Connected &&
    connection.state !== HubConnectionState.Reconnecting
  ) {
    start(connection).then(() => {
      console.info(SB, 'Sending message...');

      connection
        .invoke('Send', message.data)
        .catch((e) => console.error('SmartBot => Send message error ', e));
    });
  } else */ if (connection && connection.state === HubConnectionState.Connected) {
    console.info(SB, 'Sending message...');

    connection.invoke('Send', message.data).catch((e) => console.error('SmartBot => Send message error ', e));
  }
}

export function sendMessageFactory(
  userID: string,
  clientData: string,
  channelKey: string,
  hubConnection?: HubConnection,
): SendMessageFunc {
  return (messagePayload) => {
    const { value, type, botMessageReplyId, replyActionIndex, isFirst } = messagePayload;

    if (value.trim() === '') {
      return;
    }

    const inputText = value
      .split('\n')
      .filter((item) => item !== '')
      .join('\n');

    const message: UserMessage = {
      type: SENDER_TYPE.user,
      isMine: true,
      startsSequence: true,
      endsSequence: true,
      showTimestamp: true,
      data: {
        inputText,
        userID,
        clientData,
        userSubscription: null,
        payload: channelKey,
        channelId: channelKey,
        messageType: type,
        time: new Date(),
        origin: window.location.origin,
        botMessageReplyId,
        replyActionIndex,
        id: uuidv4(),
      },
    };

    setIsTyping(true);

    if (!isFirst) {
      setMessagesHistory(message);
    }

    setSelectedMessage(message);
    sendMessageToWS(hubConnection, message);
  };
}

export const suggestParse = (responseActions: string): string[] => {
  const regex = /<suggest>(.*?)<\/suggest>/;
  const match = responseActions.match(regex);

  if (match && match[1]) {
    return match[1].split(',');
  }

  return [];
};

export function formatResponseText(data: Record<string, any>): string {
  let responseText;

  if (data.responseActions) {
    const startIndex = data.responseActions.indexOf('<html>') + 6;
    const endIndex = data.responseActions.indexOf('</html>');

    if (startIndex > 5 && endIndex > -1) {
      responseText = data.responseActions.substr(startIndex, endIndex - startIndex);
    } else {
      responseText = data.responseText;
    }
  } else if (data.responseText) {
    responseText = data.responseText;
  } else {
    return data.responseText;
  }

  if (responseText && responseText.includes('%')) {
    const hex = '0123456789abcdefABCDEF';
    const strArray = responseText.split('%');
    // eslint-disable-next-line prefer-destructuring
    responseText = strArray[0];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < strArray.length; i++) {
      responseText += '%';

      if (strArray[i].length < 2 || !hex.includes(strArray[i][0]) || !hex.includes(strArray[i][1]))
        responseText += '25';
      responseText += strArray[i];
    }
  }

  return decodeURIComponent(responseText);
}
