import { Dispatch } from 'react';

import type { ActionCreator } from './createAction';
import type { Action } from './useReducer';
import type { NonReactStatics } from './hoistStatics';
import { hoistNonReactStatics } from './hoistStatics';

export type BoundActionCreator = ((...args: any[]) => any) & NonReactStatics<ActionCreator<any>, Record<string, any>>;
export type BoundActionCreators = Record<string, BoundActionCreator>;

function bindActionCreator(
  actionCreator: ActionCreator<any>,
  dispatch: Dispatch<Action<any>>,
): ((...args) => any) & NonReactStatics<ActionCreator<any>> {
  return hoistNonReactStatics(function () {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return dispatch(actionCreator.apply(this, arguments));
  }, actionCreator);
}

export function bindActionCreators(
  actionCreators: Record<string, ActionCreator<any>>,
  dispatch: (action: Action<any>) => void,
): BoundActionCreators {
  const boundActionCreators: BoundActionCreators = {};

  Object.keys(actionCreators).forEach((key) => {
    const actionCreator = actionCreators[key];

    if (typeof actionCreator === 'function') {
      boundActionCreators[key] = bindActionCreator(actionCreator, dispatch);
    }
  });

  return boundActionCreators;
}
