import React from 'react';
import Styler from 'stylefire';
import { easeInOut, animate } from 'popmotion';
import { Scrollbar } from 'react-scrollbars-custom';
// import { List } from 'react-virtualized';

// import { SENDER_TYPE } from '../../constants';
// import { History } from '../../../types';

export const scrollDown = (scrollRef: React.MutableRefObject<Scrollbar | null>, duration = 800): void => {
  if (scrollRef && scrollRef.current && scrollRef.current.scrollerElement) {
    const styler = Styler(scrollRef.current.scrollerElement);
    animate({
      autoplay: true,
      ease: easeInOut,
      from: scrollRef.current.scrollTop,
      to: scrollRef.current.scrollHeight /* - scrollRef.current.clientHeight */,
      duration,
      onUpdate: (latest) => {
        styler.set('scrollTop', latest);
      },
    });
  }
};

// export function scrollToBottom(listRef: React.MutableRefObject<List | null>): void {
//   if (listRef && listRef.current) {
//     const { props } = listRef.current;
//
//     listRef.current.scrollToRow(props.rowCount - 1);
//   }
// }

// export const isNeedScrollToBotMessage = (messagesHistory: History): boolean => {
//   const prevMessage = messagesHistory[messagesHistory.length - 2];
//
//   if (prevMessage) {
//     return prevMessage.type !== SENDER_TYPE.bot;
//   }
//
//   return true;
// };

export const parseHtml = (htmlStr: string): string[] => htmlStr.replace(/(<(\/?[^>]+)>)/g, '').split(',');
