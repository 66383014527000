import { styled } from '@mui/material/styles';

export const BodyWrapper = styled('div', { name: 'BodyWrapper', slot: 'Root' })(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  '&:before': {
    content: '" "',
    position: 'absolute',
    display: 'block',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: `url('${theme.customImages.logoUrl}')`,
    backgroundSize: `${theme.custom.logoSize}`,
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    opacity: 0.3,
  },

  '.ScrollbarsCustom-Track': {
    background: `${theme.custom.scrollBackground} !important`,
    width: `${theme.custom.scrollWidth} !important`,
    borderRadius: '5px !important',
    marginRight: '3px',
  },
  '.ScrollbarsCustom-Content': {
    position: 'relative',
    scrollBehavior: 'smooth',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  '.ScrollbarsCustom-Thumb': {
    transition: '0.1s !important',
    background: `${theme.custom.scrollThumbBackground} !important`,
  },
}));

export const BodyBox = styled('div', { name: 'BodyBox', slot: 'Root' })({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  padding: '0 2px 0 3px',
  // '@media only screen and (max-width: 1024px)': {
  //   overflow: 'hidden',
  // },
});
