import React from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { createProxy } from '../createProxy';

const cache = new WeakMap();

export default createProxy({}, 'emotion', ({ root, children }) => {
  const options =
    cache.get(root) ||
    (() => {
      const opts = createCache({ container: root, key: 'react-shadow' });
      cache.set(root, opts);
      return opts;
    })();

  return <CacheProvider value={options}>{children}</CacheProvider>;
});
