import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import useTheme from '@mui/styles/useTheme';
import { Theme } from '@mui/material';
import { AvatarWrapper } from './styled';

type AvatarProps = {
  /** Avatar image source */
  src: string;
  isMine: boolean;
};

export function Avatar(props: AvatarProps): JSX.Element {
  const { src = '', isMine = false } = props;
  const theme = useTheme<Theme>();
  const Component = isMine ? AccountCircleIcon : AccountCircleOutlinedIcon;

  const mineRootStyles = {
    marginLeft: 0,
    marginRight: '6px',
    color: theme.custom.messageColor,
    background: theme.custom.messageIconBackground,
    boxShadow: '3px 3px 5px rgba(0,0,0,0.6)',
  };

  return (
    <AvatarWrapper style={isMine ? mineRootStyles : {}}>
      {src ? <img src={src} alt="avatar" /> : <Component />}
    </AvatarWrapper>
  );
}
