import { getStorage, hasStorage, isFunction, isString, iterateStorage, noop, removePrefix, trim } from './utils';

type Options = { driver?: string; keyPrefix?: string };
type StorageType = 'localStorage' | 'sessionStorage';

export class WidgetCacheStorage {
  private options: Options;

  private _storageType: string;

  private readonly _driver: Storage;

  private readonly _keyPrefix: string;

  static isAvailable: (storageType: StorageType) => boolean;

  static createInstance: (options: Options) => WidgetCacheStorage;

  constructor(options: Options) {
    const defaults = {
      driver: 'sessionStorage',
      keyPrefix: 'metadialog',
    };

    this.options = {
      driver: options.driver ? options.driver : defaults.driver,
      keyPrefix: options.keyPrefix ? options.keyPrefix : defaults.keyPrefix,
    };

    if (this.options.driver !== 'localStorage' && this.options.driver !== 'sessionStorage') {
      throw new Error('The "driver" option must be one of "localStorage" or "sessionStorage".');
    }

    if (!isString(this.options.keyPrefix)) {
      throw new TypeError('The "keyPrefix" option must be a string.');
    }

    this._storageType = this.options.driver;
    this._driver = getStorage(this.options.driver);
    this._keyPrefix = trim(this.options.keyPrefix as string);
  }

  constructKey(key: string) {
    return `${this._keyPrefix}-${key}`;
  }

  getItem(key, onErrorCallback = noop): any {
    if (!isString(key)) {
      throw new TypeError("Failed to execute 'getItem' on 'Storage': The first argument must be a string.");
    }

    let res = null;

    try {
      const item = this._driver.getItem(this.constructKey(key));
      res = item ? JSON.parse(item) : null;
    } catch (error) {
      onErrorCallback(error);
    }

    return res;
  }

  setItem(key, value, onErrorCallback = noop) {
    if (!isString(key)) {
      throw new TypeError("Failed to execute 'setItem' on 'Storage': The first argument must be a string.");
    }

    try {
      this._driver.setItem(this.constructKey(key), JSON.stringify(value == null || isFunction(value) ? null : value));
    } catch (error) {
      onErrorCallback(error);
    }
  }

  removeItem(key, onErrorCallback = noop) {
    if (!isString(key)) {
      throw new TypeError("Failed to execute 'removeItem' on 'Storage': The first argument must be a string.");
    }

    try {
      this._driver.removeItem(this.constructKey(key));
    } catch (error) {
      onErrorCallback(error);
    }
  }

  clear(onErrorCallback = noop) {
    try {
      iterateStorage(this, this._driver.removeItem.bind(this._driver));
    } catch (error) {
      onErrorCallback(error);
    }
  }

  keys(onErrorCallback = noop) {
    const res: Array<string> = [];

    try {
      iterateStorage(this, (key) => res.push(removePrefix(key, this._keyPrefix)));
      return res;
    } catch (error) {
      onErrorCallback(error);
    }

    return null;
  }

  length(onErrorCallback = noop) {
    try {
      return this.keys()?.length;
    } catch (error) {
      onErrorCallback(error);
    }

    return 0;
  }

  iterate(iteratorCallback, onErrorCallback = noop) {
    if (!isFunction(iteratorCallback)) {
      throw new TypeError("Failed to iterate on 'Storage': 'iteratorCallback' must be a function.");
    }

    try {
      iterateStorage(this, (key, value) => {
        const _key = removePrefix(key, this._keyPrefix);
        const _value = JSON.parse(value);
        iteratorCallback.call(this, _value, _key);
      });
    } catch (error) {
      onErrorCallback(error);
    }
  }
}

WidgetCacheStorage.isAvailable = (storageType) => hasStorage(storageType);

WidgetCacheStorage.createInstance = (options) => new WidgetCacheStorage(options);
